import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid-pro';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import FilterChip from '../../../ui-library/components/filter-chip/FilterChip';
import { CheckCircleOutline } from '@mui/icons-material';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import ActionContainer from '../../common/components/ActionContainer';
import InviteBackerModal from '../modals/InviteBackerModal';
import BulkInviteBackerModal from '../modals/BulkInviteBackerModal';
import BulkReInviteBackerModal from '../modals/BulkReInviteBackerModal';
import ReInviteBackerModal from '../modals/ReInviteBackerModal';
import { Link } from 'react-router-dom';

function Backers() {
  const rowData = [
    {
      name: 'Aravind Sai',
      date: new Date('12/01/2021'),
      series_A: 150,
      series_B: 100,
      total_shares: 330,
      fully_diluted_shares: '80,000',
      amount_invested: '₹ 20,00,00,000'
    },
    {
      name: 'RahulJaikar',
      date: new Date('10/03/2021'),
      series_A: 180,
      series_B: 100,
      total_shares: 340,
      fully_diluted_shares: '30,000',
      amount_invested: '₹ 6,00,00,000'
    },
    {
      name: 'Iswar Deewan',
      date: new Date('11/02/2021'),
      series_A: 150,
      series_B: 100,
      total_shares: 350,
      fully_diluted_shares: '60,000',
      amount_invested: '₹ 12,00,00,000'
    },
    {
      name: 'Manish Rathode',
      date: new Date('10/01/2022'),
      series_A: 160,
      series_B: 130,
      total_shares: 380,
      fully_diluted_shares: '50,000',
      amount_invested: '₹ 10,00,00,000'
    },
    {
      name: 'Ray Johnson',
      date: new Date('02/19/2022'),
      series_A: 250,
      series_B: 110,
      total_shares: 350,
      fully_diluted_shares: '50,000',
      amount_invested: '₹ 10,00,00,000'
    }
  ];

  const breadcrumbData = {
    breadcrumbSeparator: false,
    stepKey: 1,
    steps: [
      {
        title: 'DASHBOARD - BACKERS',
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Backers',
      navigationButton: false,
      helpText: 'Backers'
    }
  };

  const SUMMARY_STATUS_ENUM = {
    ALL: 'all',
    INVITED: 'invited',
    ONBOARDED_TO_AIF: 'onboarded_to_aif'
  };

  const [filters, setFilters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(SUMMARY_STATUS_ENUM.ALL);

  const [openInviteBackerModal, setOIBM] = useState(false);
  const [openBulkInviteBackerModal, setOBIBM] = useState(false);
  const [openReInviteBackerModal, setORIBM] = useState(false);
  const [openBulkReInviteBackerModal, setOBRIBM] = useState(false);

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'NAME',
      isSearchable: true,
      renderCell: (params: any) => <Link to={`/backers/${params?.row?.name}`}>{params?.row?.name}</Link>
    },
    {
      field: 'email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'EMAIL',
      isSearchable: true
    },
    {
      field: 'mobile',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'MOBILE'
    },
    {
      field: 'onboarding_status',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'ONBOARDING STATUS'
    },
    {
      field: 'invited_to_syndicate',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'INVITED TO SYNDICATE',
      type: 'date'
    },
    {
      field: 'onboarded_to_aif',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'ONBOARDED TO AIF',
      type: 'date'
    },
    {
      field: 'transferred',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'number',
      headerName: 'TOTAL TRANSFERRED'
    },
    {
      field: 'deals',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 100,
      type: 'number',
      headerName: '#DEALS'
    },
    {
      field: 'unallocated_funds',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 150,
      headerName: 'Unallocated Funds'
    },
    {
      field: 'actions',
      minWidth: 250,
      headerName: 'ACTIONS',
      renderCell: (params: any) => {
        return (
          <ActionContainer>
            <LinkButton
              onClick={() => {
                setORIBM(params.row);
              }}
              title="re-invite"
            ></LinkButton>
            <LinkButton onClick={() => {}} title="block"></LinkButton>
          </ActionContainer>
        );
      }
    }
  ];

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <InviteBackerModal openModal={openInviteBackerModal} handleClose={() => setOIBM(false)} />
      <BulkInviteBackerModal openModal={openBulkInviteBackerModal} handleClose={() => setOBIBM(false)} />
      <ReInviteBackerModal openModal={openReInviteBackerModal} handleClose={() => setORIBM(false)} />
      <BulkReInviteBackerModal openModal={openBulkReInviteBackerModal} handleClose={() => setOBRIBM(false)} />
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <ActionButton onClick={() => setOBRIBM(true)}>Bulk Re-Invite Backers</ActionButton>
          <ActionButton onClick={() => setOBIBM(true)}>Bulk Invite Backers</ActionButton>
          <ActionButton onClick={() => setOIBM(true)}>Invite Backer to Syndicate</ActionButton>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} width="fit-content" alignItems="center" mb={2} mt={1}>
        <Typography fontSize="14px">Filter By Status:</Typography>

        <FilterChip
          label="Invited"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === SUMMARY_STATUS_ENUM.INVITED}
          onClick={() => {
            setSelectedStatus(SUMMARY_STATUS_ENUM.INVITED);
          }}
        />
        <FilterChip
          label="Onboarded to AIF"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === SUMMARY_STATUS_ENUM.ONBOARDED_TO_AIF}
          onClick={() => {
            setSelectedStatus(SUMMARY_STATUS_ENUM.ONBOARDED_TO_AIF);
          }}
        />
      </Stack>
      <LvComplexTable
        rows={rowData}
        columns={columnsData}
        leftPinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'name']}
        activeFilters={filters}
        // filterMode="server"
        checkboxSelection
        onselectionChange={(selectedRows: any) => console.log(selectedRows)}
        enableAdvanceNumberFiltering
        onFilterChange={(filters: any) => setFilters(filters)}
        rowHeight={48}
        headerHeight={50}
      />
    </Box>
  );
}

export default Backers;
