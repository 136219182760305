import React, { forwardRef, ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import { getColor } from '../../colors';

export type SimpleTooltipProps = {
  /**
   * content to render inside tooltip
   */
  content?: ReactNode;
  /**
   * element on which to hover, to show up the tooltip
   */
  children?: ReactNode;
  /**
   * placement of the tooltip
   */
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  /**
   * show/hide tooltip arrow
   */
  showArrow?: boolean;
  /**
   * Disable hover listener.
   * Can be useful in situations like in a text field when tooltip
   * should be visible only on focus and not on hover
   */
  disableHoverListener?: boolean;
  /**
   * Disable touch listener.
   * Can be useful in situations like in a text field when tooltip
   * should be visible only on focus and not on hover
   */
  disableTouchListener?: boolean;
  /**
   * custom styling for the tooltip (in MUI sx object)
   */
  tooltipCustomStyles?: object;
  /**
   * custom styling for the target(element on which to hover) container
   */
  targetContainerCustomStyles?: object;
};

const SimpleTooltipWrapper = forwardRef((props: any, ref: any) => {
  const { children, targetContainerCustomStyles, ...otherProps } = props;
  return (
    <span {...otherProps} ref={ref} style={{ width: '100%', ...targetContainerCustomStyles }}>
      {children}
    </span>
  );
});

export function SimpleTooltip({
  children = null,
  content = null,
  placement = 'right',
  showArrow = true,
  disableHoverListener = false,
  disableTouchListener = false,
  tooltipCustomStyles = {},
  targetContainerCustomStyles = {}
}: SimpleTooltipProps) {
  return (
    <Tooltip
      title={content}
      arrow={showArrow}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            color: getColor('neutral', 900),
            border: '1px solid',
            borderColor: getColor('extra', 2000),
            backgroundColor: getColor('shades', 0),
            '& .MuiTooltip-arrow': {
              '&:before': {
                border: '1px solid',
                borderColor: getColor('extra', 2000)
              },
              color: getColor('shades', 0)
            },
            maxWidth: 'unset',
            ...tooltipCustomStyles
          }
        }
      }}
    >
      <SimpleTooltipWrapper targetContainerCustomStyles={targetContainerCustomStyles}>{children}</SimpleTooltipWrapper>
    </Tooltip>
  );
}
