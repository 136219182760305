import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { getColor } from '../../colors';

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      fontSize: '0.75rem',
      textAlign: 'center',
      margin: '0.25rem',
      textOverflow: 'ellipsis',
      borderRadius: '0.25rem',
      backgroundColor: getColor('extra', 900),
      color: getColor('extra', 800),
      lineHeight: 2,
      display: '-webkit-inline-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      padding: '0.125rem 0.25rem',
      fontWeight: 500
    }
  })
);
export type LvSimpleTagsProps = {
  /**
   * styles to be applied to the child component.
   */
  customStyles?: object;
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;
};

export function LvSimpleTags({ customStyles = {}, children }: LvSimpleTagsProps) {
  const classes = useStyles();
  return (
    <Box className={classes.chip} sx={customStyles}>
      {children}
    </Box>
  );
}
