import React, { useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { getColor } from '../../colors';
import { LVThemeProvider } from '../theme/lvtheme';

const useStyles = makeStyles({
  pricingValue: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: getColor('extra', 100)
  },
  arrowIcon: {
    width: '24px',
    height: 'auto',
    cursor: 'pointer'
  }
});

export type InfoCardProps = {
  /**
   * info card heading
   */
  heading?: string;
  /**
   * info card description
   */
  description?: string;
  /**
   * info card pricing
   */
  pricing?: string | number;
  /**
   * info card height
   */
  height?: string;
  /**
   * info card width
   */
  width?: string;
  /**
   * function for click on icon
   */
  onClick?: Function;
};

export function InfoCard(props: InfoCardProps) {
  const { heading, description, onClick, pricing, height = 'auto', width = '460px' } = props;
  const classes = useStyles();
  const [isHovering, setHoverState] = useState<boolean>(false);

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <LVThemeProvider>
      <Box
        onMouseEnter={() => {
          setHoverState(true);
        }}
        onMouseLeave={() => {
          setHoverState(false);
        }}
        sx={{
          backgroundColor: getColor('shades', 0),
          borderRadius: '8px',
          padding: '14px 18px 16px 16px',
          display: 'flex',
          '&:hover': {
            boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.3)'
          }
        }}
        height={height}
        width={width}
      >
        <Grid container direction="column">
          <Grid item container>
            <Grid item>
              <Typography marginBottom="5px" variant="heading_05_bold" color={getColor('border', 200)}>
                {heading}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography marginBottom="9px" variant="heading_05_medium" color={getColor('border', 300)}>
              {description}
            </Typography>
          </Grid>
          {pricing && (
            <Grid item>
              <Typography variant="heading_05_bold" color={getColor('extra', 0)}>
                Plan starts at <span className={classes.pricingValue}>{`₹${pricing}`}</span>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid>
          <div className={classes.arrowIcon} onClick={handleClick}>
            <img
              src={
                isHovering
                  ? 'https://dsjvxb1plg419.cloudfront.net/v2.0/Simple-Dark-Right-Arrow.svg'
                  : 'https://dsjvxb1plg419.cloudfront.net/v2.0/Vector-2.svg'
              }
              alt="arrow-icon"
            />
          </div>
        </Grid>
      </Box>
    </LVThemeProvider>
  );
}
