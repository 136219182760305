import React from 'react';
import { TimelineControl } from '../../../ui-library/components/timeline/TimelineControl';
import { Box, Stack } from '@mui/material';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { CardContainer } from '../../../ui-library/components/card/card-container';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { DocumentListCard } from '../../../ui-library/components/card/document-list-card';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';

const DraftDeal = () => {
  return (
    <Box
      className="card_border"
      sx={{
        padding: '1.875rem'
      }}
    >
      <Box gap={2} display="flex" alignItems="center" mb={2}>
        <img
          src="https://fastly.picsum.photos/id/428/200/200.jpg?hmac=t9FYhwylg9uE-Y2lJluz7aIxlV_-R2FJQZV8UpoOu7M"
          style={{ height: 25, aspectRatio: 1, borderRadius: '3px' }}
        />
        <SectionHeader>Startup Name</SectionHeader>
      </Box>
      <Box pl={3}>
        <TimelineControl
          items={[
            {
              label: 'Step 1: Edit Deal Info and submit to LetsVenture team for review.',
              title: 'submitted for review: Jul 1, 2024',
              render: <OutlinedButton>Edit Deal / Submit to LV for Review</OutlinedButton>
            },
            {
              label: 'Step 2: Approval from LetsVenture team',
              title: 'approved by LV Team: Jul 12, 2024',
              checked: true,
              render: (
                <Stack direction="row" gap={2}>
                  <DocumentListCard documentName="Scheme Doc" width="20%" />
                  <DocumentListCard documentName="Lead Scheme Doc" width="24%" />
                </Stack>
              )
            },
            {
              label: 'Step 3: Publish the Deal',
              render: <ActionButton>Publish Deal</ActionButton>
            }
          ]}
        />
      </Box>
    </Box>
  );
};

export default DraftDeal;
