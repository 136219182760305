import React, { FC } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';

const ManageCommitModal: FC<any> = ({ openModal, handleClose }) => {
  return (
    <CommonModal submitText="continue" isOpen={!!openModal} handleClose={handleClose} title="Manage Commit" hideButtons>
      <div style={{ width: '500px', overflow: 'auto' }}>{JSON.stringify(openModal)}</div>
    </CommonModal>
  );
};

export default ManageCommitModal;
