import { Alert, Box, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { CardContainer } from '../../../ui-library/components/card/card-container';
import { Link } from 'react-router-dom';
import { DropdownButton } from '../../../ui-library/components/buttons/dropdown-button';
import { ActionOptionsList } from '../constants/IndividualBackerConstants';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { InfoOutlined } from '@mui/icons-material';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import TableLogo from '../../common/components/TableLogo';

const IndividualBacker: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - BACKERS',
        titleDisplay: true
      },
      {
        title: id,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: id,
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const cardOptions = [
    {
      label: 'Investor Name',
      spacing: 3,
      value: 'LetsVenture'
    },
    {
      label: 'Email',
      spacing: 3,
      value: 'asish.k@letsventure.com'
    },
    {
      label: 'Mobile',
      spacing: 3,
      value: '+91-9845623179'
    },
    {
      label: 'Go To',
      spacing: 3,
      renderer: (
        <Typography to="/" className="underline" component={Link} variant="heading_04_medium" fontWeight={600}>
          Profile Link
        </Typography>
      )
    },
    {
      label: 'AIF Onboarding Status',
      spacing: 3,
      value: 'COMPLETED'
    },
    {
      label: 'Invited to Syndicate',
      spacing: 3,
      value: 'Jan 1, 2024'
    },
    {
      label: 'AIF Onboarded Date',
      spacing: 3,
      value: 'Jan 24, 2024'
    },
    {
      label: 'Joined as Backer',
      spacing: 3,
      value: 'Jan 24, 2024'
    },
    {
      label: '#deals',
      spacing: 3,
      value: 4
    },
    {
      label: 'Total transferred',
      spacing: 3,
      value: '₹ 1.5 Cr'
    },
    {
      label: 'Unallocated Funds',
      spacing: 3,
      value: '₹ 5.2 Cr'
    },
    {
      label: 'Access Public Deals?',
      spacing: 3,
      value: 'NO',
      helpText: 'Access Public Deals'
    },
    {
      label: 'Blocked from Syndicate',
      spacing: 3,
      value: 'Jul 30, 2024'
    },
    {
      label: 'Last Invited to Syndicate',
      spacing: 3,
      value: 'Jan 15, 2024 (email)'
    }
  ];

  const columnsData = [
    {
      field: 'logo',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 50,
      renderCell: (params: any) => <TableLogo id={params?.row?.id} url={params?.row?.logo} />
    },
    {
      field: 'startup_name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'STARTUP NAME',
      isSearchable: true,
      renderCell: (params: any) => <Link to={`/deals/${params?.row?.id}`}>{params?.row?.startup_name}</Link>
    },
    {
      field: 'invited_to_deal',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'INVITED TO DEAL',
      type: 'date'
    },
    {
      field: 'commited_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'COMMITED DATE',
      type: 'date'
    },
    {
      field: 'commited_amount',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'COMMITED AMOUNT',
      type: 'number'
    },
    {
      field: 'drawdown_sent',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'DRAWDOWN SENT',
      type: 'date'
    },
    {
      field: 'money_recieved',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'number',
      headerName: 'MONEY RECIEVED'
    }
  ];

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <DropdownButton
            options={ActionOptionsList}
            onOptionClick={(value: string) => {
              alert(value);
            }}
            buttonText="Actions"
          />
        </Box>
      </Stack>

      <CardContainer options={cardOptions} customClass="card_border text-left" />
      <Box mt={2} mb={2}>
        <SectionHeader customStyles={{ textAlign: 'start' }}>Deals</SectionHeader>
        <Alert
          severity="info"
          sx={{ padding: '0 10px', width: 'fit-content' }}
          icon={<InfoOutlined fontSize="small" />}
        >
          Note: Only committed investors details are shown here. For live deals, visit the respective deal page itself.
        </Alert>
      </Box>

      <LvComplexTable
        rows={[]}
        columns={columnsData}
        leftPinnedColumns={['logo', 'startup_name']}
        activeFilters={filters}
        // filterMode="server"
        // checkboxSelection
        // onselectionChange={(selectedRows: any) => console.log(selectedRows)}
        // enableAdvanceNumberFiltering
        onFilterChange={(filters: any) => setFilters(filters)}
        rowHeight={48}
        headerHeight={50}
      />
    </Box>
  );
};

export default IndividualBacker;
