import { makeStyles } from '@mui/styles';
import { getColor } from '../../colors';

export const useStyles = makeStyles({
  headerBox: {
    fontSize: '13px',
    fontWeight: '600',
    fontFamily: 'Work Sans',
    color: getColor('neutral', 700)
  },
  searchContainer: {
    lineHeight: '0px !important',
    backgroundColor: getColor('background', 300),
    height: '68px',
    width: '100%',
    padding: '0 10px'
  },
  searchBox: {
    paddingTop: '15px',
    width: '100%'
  },
  searchBoxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '96%'
  },
  textField: {
    borderRadius: '4px',
    backgroundColor: getColor('shades', 0),
    height: '37px',
    '& .MuiInputLabel-outlined': {
      color: '#818283',
      transform: 'translate(12px, 8.5px) scale(0.93)'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#888'
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 5px'
    },
    '& .MuiInputAdornment-root': {
      marginLeft: '8px',
      marginRight: '0px'
    },
    '& .MuiInputAdornment-positionEnd ': {
      marginLeft: '0px',
      marginRight: '15px'
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
      padding: 0,
      fontWeight: '400 !important',
      '& fieldset': {
        border: '1px solid #dedede'
      },
      '&:hover fieldset': {
        borderColor: '#dedede'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#888'
      }
    },
    '& ::placeholder': {
      color: 'black !important'
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: getColor('shades', 100),
      WebkitTextFillColor: getColor('shades', 100)
    },
    '& .Mui-disabled': {
      cursor: 'default !important'
    }
  },
  disabledTextField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #dedede',
        pointerEvents: 'auto'
      }
    }
  },
  cellCheckbox: {
    '& .MuiCheckbox-root': {
      color: `${getColor('primary', 500)} !important`
    }
  },
  columnHeaderCheckbox: {
    '& .MuiDataGrid-columnHeaderTitleContainer svg': {
      marginLeft: '3.6px'
    },
    '& .MuiCheckbox-root': {
      color: `${getColor('primary', 500)} !important`
    }
  }
});
