import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { getColor } from '../../colors';
import { LVThemeProvider } from '../theme/lvtheme';
import { checkBox } from './lv-user-role-style';
import { LvSimpleTags } from './lv-simple-tags';

type cardData = {
  /**
   * state of the checkbox
   */
  isEnabled?: boolean;
  /**
   * role name to be displayed on the card
   */
  roleName?: string;
  /**
   * array for roles to be displayed in simple tags
   */
  idealFor?: string[];
  /**
   * description of the role on the card
   */
  description?: string;
};

type customClasses = {
  /**
   * custom class for entire card content
   */
  content?: string;
  /**
   * custom class for role name
   */
  roleName?: string;
  /**
   * custom class for description
   */
  description?: string;
  /**
   * custom class for checkbox parent
   */
  checkboxParent?: string;
};

const useStyles = makeStyles({
  roleName: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    color: getColor('border', 200)
  },
  description: {
    fontWeight: 400,
    fontSize: '0.8125rem',
    lineHeight: '1.25rem',
    color: getColor('neutral', 900)
  },
  content: {
    padding: '7px 0'
  },
  checkboxParent: { textAlign: 'center' },
  tagHolder: { display: 'flex', flexWrap: 'wrap', alignItems: 'center' }
});

export type LvUserRoleCardProps = {
  /**
   * user roles card data
   */
  cardData?: cardData;
  /**
   * user roles card height
   */
  height?: string;
  /**
   * user roles card width
   */
  width?: string;
  /**
   * function for click on checkbox
   */
  onClick?: Function;
  /**
   * custom classes for each element
   */
  customClasses?: customClasses;
  /**
   * custom styling for tags
   */
  tagStyles?: {};
};

export function LvUserRoleCard(props: LvUserRoleCardProps) {
  const {
    cardData = { roleName: '', idealFor: [''], description: '', isEnabled: false },
    onClick,
    height = 'auto',
    width = '460px',
    customClasses = {},
    tagStyles = {}
  } = props;
  const classes = useStyles();

  const handleClick = (e: any) => {
    onClick && onClick(e);
  };

  return (
    <LVThemeProvider>
      <Box
        sx={{
          backgroundColor: getColor('shades', 0),
          borderRadius: '8px',
          padding: '14px 18px 16px 16px',
          display: 'flex',
          '&:hover': {
            boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.3)'
          },
          border: `1px solid ${getColor('neutral', 100)}`
        }}
        height={height}
        width={width}
      >
        <Grid container direction="row">
          <Grid item xs={1} className={`${classes.checkboxParent} ${customClasses?.checkboxParent ?? ''}`}>
            <Checkbox
              checked={cardData.isEnabled}
              value={cardData.isEnabled}
              onChange={(e) => handleClick(e)}
              size="small"
              sx={checkBox}
            />
          </Grid>
          <Grid
            item
            xs={11}
            container
            direction="column"
            className={`${classes.content} ${customClasses?.content ?? ''}`}
          >
            <Grid item>
              <Typography className={`${classes.roleName} ${customClasses?.roleName ?? ''}`}>
                {cardData.roleName}
              </Typography>
            </Grid>
            <Grid item>
              <span className={classes.tagHolder}>
                <Typography className={`${classes.description} ${customClasses?.description ?? ''}`}>
                  Ideal for:{' '}
                </Typography>
                {cardData.idealFor?.map((designation, index) => (
                  <LvSimpleTags customStyles={tagStyles} key={index}>
                    {designation}
                  </LvSimpleTags>
                ))}
              </span>
            </Grid>
            <Grid item>
              <Typography className={`${classes.description} ${customClasses?.description ?? ''}`}>
                {cardData.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LVThemeProvider>
  );
}
