import { getColor } from '../../colors';

export const dropdownStyle = {
  // width: 245,
  '& .MuiInputLabel-outlined': {
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: '14px',
    color: `${getColor('primary', 700)} !important`,
    borderColor: getColor('extra', 600),
    transform: 'translate(14px, 10px) scale(1)'
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    fontSize: '12px',
    transform: 'translate(13px, -8px) scale(1)'
  },

  '& .MuiOutlinedInput-input': {
    padding: '1px 4px !important',
    fontSize: '14px',
    fontFamily: 'Work Sans',
    fontWeight: 400
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '4px',
    border: `1px solid ${getColor('primary', 700)} !important`,
    '& legend': {
      fontSize: '12px',
      '& span': {
        padding: '0 4px'
      }
    }
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 'unset',
    right: '12px !important'
  }
};

export const noOutlineDropdownStyle = {
  // width: 245,
  '& .MuiInputLabel-outlined': {
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: '14px',
    color: `${getColor('primary', 700)} !important`,
    borderColor: getColor('extra', 600),
    transform: 'translate(14px, 10px) scale(1)'
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    fontSize: '12px',
    transform: 'translate(13px, -8px) scale(1)'
  },

  '& .MuiOutlinedInput-input': {
    padding: '1px 4px !important',
    fontSize: '14px',
    fontFamily: 'Work Sans',
    fontWeight: 400
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '4px',
    border: `1px solid ${getColor('primary', 700)} !important`,
    '& legend': {
      fontSize: '12px',
      '& span': {
        padding: '0 0px'
      }
    }
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 'unset',
    right: '12px !important'
  }
};

export const primaryStyle = {
  '& .MuiInputBase-root': {
    height: 40
  },
  '& .MuiInputLabel-outlined': {
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: '14px',
    color: `${getColor('extra', 600)} `,
    borderColor: getColor('extra', 600),
    transform: 'translate(14px, 10px) scale(1)'
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    fontSize: '12px',
    transform: 'translate(13px, -8px) scale(1)'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '4px',
    '& legend': {
      fontSize: '12px',
      '& span': {
        padding: '0 4px'
      }
    }
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: `${getColor('primary', 700)} `
  },
  '&:hover .MuiInputLabel-outlined': {
    color: `${getColor('primary', 700)} `
  },
  '& .MuiOutlinedInput-root': {
    padding: '4px !important',
    fontSize: '14px',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    '& fieldset': {
      border: `1px solid ${getColor('extra', 2000)}`
    },
    '&:hover fieldset': {
      borderColor: getColor('primary', 700)
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${getColor('primary', 700)}`
    }
  },
  '& .MuiInputLabel-outlined.Mui-error': {
    color: '#e02020'
  },
  '& .MuiAutocomplete-endAdornment': {
    top: 'unset',
    right: '12px !important'
  }
};
