import { Component } from 'react';
// import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
// import { getColor } from "@trica-equity/ui-library.styles.colors";
// import { NoDataAvailable } from "@trica-equity/ui-library.error.no-data-available";

interface IErrorBoundaryProps {
  children?: any;
}

interface IErrorBoundaryState {
  error?: any;
}

export default class ErrorBoundary extends Component<Partial<IErrorBoundaryProps>, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so next render shows fallback UI.
    return { error };
  }

  componentDidCatch(error: any) {
    this.setState({ error });
  }

  async handleRefreshClick() {
    // unregister any service workers
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
    // clear cache
    caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
    // reload page
    setTimeout(() => {
      window.location.href = `/`;
    }, 1000);
  }

  render() {
    const { error } = this.state;

    if (error) {
      const isChunkLoadError = error.toString().includes('ChunkLoadError:');
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              //   background: getColor("shades", 0),
              borderRadius: '12px',
              padding: '30px',
              minWidth: '460px',
              maxWidth: '70%'
            }}
          >
            {/* <NoDataAvailable
              title={
                isChunkLoadError
                  ? "Platform has been updated, please refresh your browser to see the changes"
                  : "Something went wrong!"
              }
              subTitle={isChunkLoadError ? "" : error.toString()}
              buttonComponent={
                <ActionButton
                  onClick={this.handleRefreshClick}
                  customStyles={{ padding: "11px 50px" }}
                >
                  Reload application
                </ActionButton>
              }
            /> */}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
