import React from 'react';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { Box, Grid } from '@mui/material';
import { ToggleSwitch } from '../buttons/toggle-switch';
import { getColor } from '../../colors';

export type ToggleableCardProps = {
  /**
   * an object that contains details to be rendered in the card.
   * id - ID of the item
   * label - text to be rendered in the card
   * enabled - state of the switch
   */
  item: { id: string; label: string; enabled: boolean };

  /**
   * a function to be called when the switch is toggled.
   */
  onSwitchToggle: Function;

  /**
   * A boolean value to disable the switch (optional).
   */
  disable?: boolean;
  /**
   * custom styling of the card. Accepts styling for MUI sx prop
   */
  customStyles?: object;
};

export function ToggleableCard({ item, disable, onSwitchToggle, customStyles }: ToggleableCardProps) {
  return (
    <Grid
      container
      direction="row"
      flexWrap="nowrap"
      display="flex"
      alignItems="center"
      height="43px"
      sx={{ backgroundColor: getColor('background', 400), borderRadius: '4px', minWidth: '300px', ...customStyles }}
    >
      <Grid item marginLeft="12px" marginTop="6px" color={getColor('primary', 700)}>
        <DehazeIcon />
      </Grid>
      <Grid item marginLeft="12px" marginRight="12px">
        {item.label}
      </Grid>
      <Grid item display="flex" flex="1 1 auto">
        <Box
          style={{
            marginLeft: 'auto',
            marginRight: '-4px'
          }}
        >
          <ToggleSwitch
            onSwitchToggle={(checked: boolean) => onSwitchToggle(item.id, checked)}
            defaultChecked={item.enabled}
            isDisabled={disable}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
