import React, { ReactNode, CSSProperties } from 'react';
import { IconButton } from '@mui/material';

export type CommonIconButtonProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;
  /**
   * onClick function for icon
   */
  onClick?: Function;
  /**
   * path for the image or icon to be rendered
   */
  imgSrc?: string;
  /**
   * alternate text for the icon
   */
  alt?: string;
  /**
   * width of the icon
   */
  width?: string | number;
  /**
   * height of the icon
   */
  height?: string | number;
  /**
   * custom style for icon button
   */
  customButtonStyles?: CSSProperties;
};

export function CommonIconButton({
  onClick,
  children,
  imgSrc,
  width,
  height,
  customButtonStyles,
  alt
}: CommonIconButtonProps) {
  return (
    <IconButton sx={customButtonStyles} onClick={onClick && onClick()}>
      {children ? children : <img src={imgSrc} width={width} height={height} alt={alt} />}
    </IconButton>
  );
}
