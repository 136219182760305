import React, { useState } from 'react';
import { HorizontalTabs } from './tabs/horizontal-tabs';
import { VerticalTabs } from './tabs/vertical-tabs';
import { SimpleTooltip } from './tooltips/simple-tooltip';
import { ActionButton } from './buttons/action-button';
import { CommonIconButton } from './buttons/common-icon-button';
import { getColor } from '../colors';
import AndroidIcon from '@mui/icons-material/Android';
import { DropdownButton } from './buttons/dropdown-button';
import { NavigationButton } from './buttons/navigation-button';
import { OutlinedButton } from './buttons/outlined-button';
import { ToggleButtons } from './buttons/toggle-buttons';
import { ToggleSwitch } from './buttons/toggle-switch';
import { HeaderDetail } from './header/header-detail';
import { SectionHeader } from './header/section-header';
import { AccordionPanel } from './panels/accordion-panel';
import { ExpansionPanel } from './panels/expansion-panel';
import { SideDrawer } from './panels/side-drawer';
import { Box, Typography } from '@mui/material';
import { CardContainer } from './card/card-container';

import { makeStyles } from '@mui/styles';
import { DocumentListCard } from './card/document-list-card';
import { ErrorCard } from './card/error-card';
import { InfoCard } from './card/info-card';
import { OptionWithEmployeesStatsticCard } from './card/option-with-employees-statstic-card';
import { LvSimpleTags } from './card/lv-simple-tags';
import { LvUserRoleCard } from './card/lv-user-role-card';
import { ToggleableCard } from './card/toggleable-card';
import { CollapseComponent } from './collapse/collapse-component';
import { CoreDropdown } from './dropdowns/core-dropdown';

const useStyles = makeStyles({
  containerClass: {
    backgroundColor: getColor('background', 50)
  },
  headerNode: {
    fontSize: '1.125rem',
    lineHeight: '1.875rem',
    color: getColor('neutral', 900),
    fontFamily: 'Work Sans',
    fontWeight: 600,
    marginBottom: '1.25rem'
  },
  cardContainer: {
    background: getColor('background', 300)
  }
});

const ComponentsExample = () => {
  const coreDropDownoptions = [
    { label: 'Funding Round', value: 'funding_round' },
    { label: 'Shareholder', value: 'shareholder' }
  ];
  const onClickMenuItem = (option: string, label: string) => {
    console.log('Option', option);
  };

  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = (id: any, enabled: boolean) => {
    setIsToggled(enabled);
  };
  const [isEnabled, setIsEnabled] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const styles = useStyles();
  const cardOptions = [
    {
      label: 'Share Class Type',
      value: 'Equity',
      spacing: 3
    },
    {
      label: 'Share Class Name',
      value: 'Series A',
      spacing: 3
    },
    {
      label: 'Prefix',
      value: 'EQ',
      spacing: 3
    },
    {
      label: 'Face Value',
      value: '$70',
      spacing: 3
    },
    {
      label: 'Share class it converts into',
      value: 'Class A CCPS',
      spacing: 3
    },
    {
      label: 'Custom Value',
      spacing: 3,
      renderer: (
        <Typography variant="heading_04_medium" fontWeight={600} style={{ color: getColor('secondary', 900) }}>
          This is custom value
        </Typography>
      )
    },
    {
      label: 'Notes',
      value: 'Please notify the respective stakeholders about the changes.',
      spacing: 6
    }
  ];
  const tabs = [
    {
      name: 'DISTRIBUTION BY SHARE CLASS',
      value: 'tab_a',
      content: <p>Tab A content</p>
    },
    {
      name: 'DISTRIBUTION BY GROUP',
      value: 'tab_b',
      content: <p>Tab B content</p>
    },
    {
      name: 'TOP SHAREHOLDERS',
      value: 'tab_c',
      content: <p>Tab C content</p>,
      disabled: true
    }
  ];

  const dropdownOptions = [
    { label: 'Option A' },
    { label: 'Option B', disabled: true },
    { label: 'Option C' },
    { label: 'Option D' }
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <div className="bg-cyan-600 w-full h-full">
      <h1 className="text-3xl font-bold text-white">Hello!</h1>
      <div className="flex">
        <div className="w-1/2  bg-white p-10 m-10 text-start"></div>
        <div className="w-1/2  bg-white p-10 m-10 text-start"></div>
      </div>
      <div className=" bg-white p-10 m-10 text-start">
        <h4 className="text-3xl text-black bg-white m-10 text-left">Horizontal Tabs</h4>
        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={(tab: any) => setSelectedTab(tab)}
          scrollable={false}
        />
      </div>
      <div className="bg-white p-10 m-10">
        <h4 className="text-3xl text-black bg-white m-10 text-left">Verticle Tabs</h4>
        <VerticalTabs tabs={tabs} />
        <br />
        <div className=" bg-slate-300 h-10 w-auto" />
        <VerticalTabs
          tabs={[
            {
              name: 'Tab One',
              value: 'tab_a',
              content: (
                <div>
                  <h3>Marvel Movies</h3>
                  <button>subscribe</button>
                </div>
              )
            },

            {
              name: 'Tab Two',
              value: 'tab_b',
              content: <h3>Tab B content</h3>
            },

            {
              name: 'Tab Three',
              value: 'tab_c',
              content: <h5>Tab C content</h5>,
              disabled: true
            }
          ]}
        />
        <br />
        <div className=" bg-slate-300 h-10 w-auto" />
        <VerticalTabs
          tabs={[
            {
              name: 'Options Used',
              value: 'tab_d',
              content: <h1>Tab A content</h1>
            },

            {
              name: 'Options Available',
              value: '10000',
              content: <h2>Tab B content</h2>
            },

            {
              name: 'Both',
              value: 'tab_c',
              content: <h3>Tab C content</h3>,
              disabled: true
            }
          ]}
        />
      </div>
      <div className="grid grid-cols-2">
        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">ToolTip</h4>
          <SimpleTooltip content={<p>This is a random tooltip content</p>}>main content</SimpleTooltip>
        </div>
        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">Buttons</h4>
          <ActionButton>Button 1</ActionButton>
          <ActionButton disabled={true}>Button 2</ActionButton>
          <ActionButton isLoading={true}>Button 3</ActionButton>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">CommonIconButton</h4>
          <div>
            <CommonIconButton
              imgSrc="https://brandfetch.com/letsventure.com?library=default&collection=logos&asset=idZAw912vI&view=library"
              height="40px"
              width="40px"
            />
            <br />
            <div className=" bg-slate-300 h-10 w-auto" />
            <CommonIconButton>
              <AndroidIcon sx={{ color: getColor('primary', 700) }} />
            </CommonIconButton>
          </div>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
          <DropdownButton
            options={dropdownOptions}
            onOptionClick={(value: string) => {
              alert(value);
            }}
            buttonText="Button"
          />
        </div>
        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">NavigationButton</h4>
          <NavigationButton title="view all" onClick={() => {}} />
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">OutlinedButton</h4>
          <OutlinedButton>CANCEL</OutlinedButton>
          <OutlinedButton disabled={true}>CANCEL</OutlinedButton>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">Toggle Buttons</h4>
          <ToggleButtons buttons={[{ title: 'Graph View' }, { title: 'Table View' }]} activeButtonIndex={1} />
          <ToggleButtons
            buttons={[{ title: 'Graph View' }, { title: 'Table View' }, { title: 'Chart View' }]}
            activeButtonIndex={2}
            disabled={[1]}
          />
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">ToggleSwitch</h4>
          <ToggleSwitch switchLabel="Theme" switchLabelPositon="start" onSwitchToggle={() => alert('hii')} />
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">HeaderDetail</h4>
          <HeaderDetail
            headerDetails={[
              { headerTitle: 'Name', headerData: 'Schedule 1' },
              { headerTitle: 'Name', headerData: 'Schedule 2' }
            ]}
          />
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">SectionHeader</h4>
          <SectionHeader>Hello world!</SectionHeader>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">AccordionPanel</h4>
          <AccordionPanel accordionSummaryContent={<span>Accordion Title</span>}>
            <div>Accordion Details here</div>
          </AccordionPanel>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">ExpansionPanel</h4>
          <ExpansionPanel title="Hellow World">Hello world!</ExpansionPanel>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">SideDrawer</h4>
          <>
            <SideDrawer
              openDrawer={openDrawer}
              header={<Typography>Header for drawer</Typography>}
              cancelButton
              saveButton
              onClickCancel={() => setOpenDrawer(false)}
              onClickSave={() => {}}
            >
              <div>hello</div>
            </SideDrawer>
          </>
        </div>

        <div className=" bg-gray-200  m-10 pb-10">
          <h4 className="text-3xl text-black bg-white p-10 text-left">CardContainer</h4>
          <div className="bg-white p-10 m-10">
            <CardContainer header="Share class details" options={cardOptions} />
          </div>
          <div className="bg-white p-10 m-10">
            <CardContainer headerNode={<Box className={styles.headerNode}>Custom Header</Box>} options={cardOptions} />
          </div>
          <div className="bg-white p-10 m-10">
            <CardContainer header="Share class details" subHeader="Transferor" options={cardOptions} />
          </div>

          <div className="bg-white p-10 m-10">
            <CardContainer
              header="Share class details"
              subHeaderNode={<Box className={styles.headerNode}>Custom SubHeader</Box>}
              options={cardOptions}
            />
          </div>
          <div className="bg-white p-10 m-10">
            <CardContainer options={cardOptions} />
          </div>
          <div className="bg-white p-10 m-10">
            <CardContainer options={cardOptions} />
          </div>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DocumentListCard</h4>
          <DocumentListCard documentName={'testing1'} width={'500px'} openVaultURL={'tesla.com'}></DocumentListCard>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">ErrorCard</h4>
          <ErrorCard errorName="Sample Error-404" />
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">InfoCard</h4>
          <InfoCard
            heading="Hello World"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            pricing={1200}
          />
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">OptionWithEmployeesStatsticCard</h4>
          <div style={{ border: '1px solid #ECECEC', borderRadius: '10px' }}>
            <OptionWithEmployeesStatsticCard
              title={'OPTIONS GRANTED'}
              value={'30,000'}
              height={'94px'}
              width={'222px'}
              helpText={'Total granted options'}
            />
          </div>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">LvSimpleTags</h4>
          <LvSimpleTags customStyles={{ color: '#fff' }}>Hello world!</LvSimpleTags>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">LvUserRoleCard</h4>
          <LvUserRoleCard
            cardData={{
              isEnabled: isEnabled,
              roleName: 'Admin',
              idealFor: ['Founder', 'HR Head'],
              description:
                'User has all the permissions below, he/she can only add/modify/delete other users and their permissions.'
            }}
            onClick={() => setIsEnabled(!isEnabled)}
            width="100%"
          />
          ;
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">ToggleableCard</h4>
          <div className=" bg-gray p-10 m-10 text-start">
            <ToggleableCard
              item={{ id: 'item-01', label: 'sample card label', enabled: false }}
              onSwitchToggle={handleToggle}
            />
            {isToggled && <p>Enabled</p>}
            {!isToggled && <p>Disabled</p>}
          </div>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">CollapseComponent</h4>
          <CollapseComponent>
            <h1>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores sit cumque dolorum ipsum odio, ipsam
              ex vero qui quasi rerum ut quia delectus assumenda totam optio rem eos hic at.
            </h1>
          </CollapseComponent>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">CoreDropdown</h4>
          <CoreDropdown
            label="Remind Me Later"
            options={coreDropDownoptions}
            onClickMenuItem={onClickMenuItem}
            defaultValue={{ label: 'Shareholder', value: 'shareholder' }}
          />
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>

        <div className=" bg-white p-10 m-10 text-start">
          <h4 className="text-3xl text-black bg-white m-10 text-left">DropdownButton</h4>
        </div>
      </div>
    </div>
  );
};

export default ComponentsExample;
