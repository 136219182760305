import React from 'react';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, Typography } from '@mui/material';
import { useStyles } from './active-filters-panel-styles';
import { getColor } from '../../colors';

export interface CustomGridFilterItem extends GridFilterItem {
  filterDisplayName?: string;
  fullObjectValues?: { id: number; isEnabled: boolean; name: string; value: string }[];
}

export type ActiveFiltersPanelProps = {
  /**
   * Currently applied filters
   */
  activeFilters: CustomGridFilterItem[];
  /**
   * cancel a currently active dropdown filter
   */
  dateFormatter?: (date: any) => string;
  /**
   * callback triggered when filters are changed by either cancelling or resetting
   */
  onFilterChange: (filters: CustomGridFilterItem[]) => void;
};

export function ActiveFiltersPanel({ activeFilters = [], onFilterChange, dateFormatter }: ActiveFiltersPanelProps) {
  const classes = useStyles();

  const getOperatorText = (value: string) => {
    if (value === 'startsWith') {
      return '(Starts with)';
    }
    if (value === 'contains') {
      return '(Contains)';
    }
    if (value === 'is') {
      return '(By date)';
    }
    if (value === 'range') {
      return '(By Range)';
    }
    if (value === '=') {
      return '(Equals)';
    }
    if (value === '>') {
      return '(Greater than)';
    }
    if (value === '<') {
      return '(Less than)';
    }
    return '';
  };

  const displayValue = (item: any) => {
    if (item?.operatorValue === 'startsWith' || item?.operatorValue === 'contains') {
      return item?.value;
    }
    if (item?.operatorValue === 'is') {
      return dateFormatter ? dateFormatter(item?.value) : item?.value;
    }
    if (item?.operatorValue === 'range') {
      return `${dateFormatter ? dateFormatter(item?.value?.from) : item?.value?.from} - ${
        dateFormatter ? dateFormatter(item?.value?.to) : item?.value?.to
      }`;
    }
    return item?.value;
  };

  const onResetFilters = () => {
    const filters = [...activeFilters];
    filters.forEach((element: any) => {
      element.value = '';
      delete element.fullObjectValues;
    });
    onFilterChange(filters);
  };

  const onCancelFilter = (item: any) => {
    const filters = [...activeFilters];
    filters.forEach((element: any) => {
      if (item?.columnField === element.columnField) {
        element.value = '';
      }
    });
    onFilterChange(filters);
  };

  const onCancelSelectFilter = (item: any, value: any) => {
    const filters = [...activeFilters];
    filters.forEach((element: any) => {
      if (item?.columnField === element.columnField) {
        if (element.fullObjectValues && value) {
          const filterValues = [...element.fullObjectValues];
          const foundIndex = filterValues.findIndex((filter) => filter.value === value.value);
          if (foundIndex > -1) {
            filterValues.splice(foundIndex, 1);
            element.fullObjectValues = filterValues;
            element.value = filterValues.map((filter) => filter.name);
          }
        }
      }
    });
    onFilterChange(filters);
  };

  return (
    <div>
      {activeFilters?.length > 0 && activeFilters.some((item: any) => !!item?.value) && (
        <div style={{ padding: '16px 0px', background: getColor('background', 400) }}>
          <div className={classes.flexContainer}>
            <Typography fontSize="13px" fontWeight={500} lineHeight="20px" color={getColor('neutral', 900)}>
              Filtered By:
            </Typography>
            {activeFilters?.map((item: any, index: number) => {
              if (item?.operatorValue === 'select' && item?.fullObjectValues?.length > 0) {
                return (
                  <React.Fragment key={index}>
                    {item?.fullObjectValues?.map((value: any, idx: number) => (
                      <div key={idx} className={classes.alert}>
                        <Typography className={classes.alertText}>
                          {item?.filterDisplayName ?? item?.columnField} {getOperatorText(item?.operatorValue)}:{' '}
                          <span className={classes.alertTextValue}>{value.name}</span>
                        </Typography>
                        <IconButton onClick={() => onCancelSelectFilter(item, value)}>
                          <CancelIcon sx={{ color: getColor('primary', 700), fontSize: '16px' }} />
                        </IconButton>
                      </div>
                    ))}
                  </React.Fragment>
                );
              } else if (item?.operatorValue !== 'select' && item.value !== '') {
                return (
                  <div key={index} className={classes.alert}>
                    <Typography className={classes.alertText}>
                      {item?.filterDisplayName ?? item?.columnField} {getOperatorText(item?.operatorValue)}:{' '}
                      <span className={classes.alertTextValue}>{displayValue(item)}</span>
                    </Typography>
                    <IconButton onClick={() => onCancelFilter(item)}>
                      <CancelIcon sx={{ color: getColor('primary', 700), fontSize: '16px' }} />
                    </IconButton>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <div className={classes.resetButton}>
              <Typography onClick={onResetFilters} className={classes.reset}>
                Reset
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
