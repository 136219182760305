import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import FilterChip from '../../../ui-library/components/filter-chip/FilterChip';
import { CheckCircleOutline } from '@mui/icons-material';
import { CommunicationLogStatus } from '../constants/IndividualDealConstant';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { Link } from 'react-router-dom';

const CommunicationLogs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(CommunicationLogStatus.COMMITED);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: id,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Communication Logs',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'NAME',
      isSearchable: true,
      renderCell: (params: any) => <Link to={`/`}>{params?.row?.startup_name}</Link>
    },
    {
      field: 'campaign_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'CAMPAIGN DATE',
      type: 'date'
    },
    {
      field: 'campaign_type',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'CAMPAIGN TYPE'
    },
    {
      field: 'delivered',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'DELIVERED'
    },
    {
      field: 'viewed',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'VIEWED',
      type: 'date'
    },
    {
      field: 'clicked',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'CLICKED'
    },
    {
      field: 'commited',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'number',
      headerName: 'COMMITED'
    },
    {
      field: 'commit_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'date',
      headerName: 'COMMIT DATE'
    },
    {
      field: 'transfer_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'date',
      headerName: 'TRANSFER DATE'
    }
  ];

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex" alignItems="center">
          <img
            src="https://fastly.picsum.photos/id/428/200/200.jpg?hmac=t9FYhwylg9uE-Y2lJluz7aIxlV_-R2FJQZV8UpoOu7M"
            style={{ height: 25, aspectRatio: 1 }}
          />
          <SectionHeader>{id}</SectionHeader>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} width="fit-content" alignItems="center" mb={2} mt={1}>
        <Typography fontSize="14px">Filter By Status:</Typography>

        <FilterChip
          label="Invited"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === CommunicationLogStatus.INVITED}
          onClick={() => {
            setSelectedStatus(CommunicationLogStatus.INVITED);
          }}
        />
        <FilterChip
          label="Viewed"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === CommunicationLogStatus.VIEWED}
          onClick={() => {
            setSelectedStatus(CommunicationLogStatus.VIEWED);
          }}
        />
        <FilterChip
          label="Commited"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === CommunicationLogStatus.COMMITED}
          onClick={() => {
            setSelectedStatus(CommunicationLogStatus.COMMITED);
          }}
        />
        <FilterChip
          label="Transferred"
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === CommunicationLogStatus.TRANSFERRED}
          onClick={() => {
            setSelectedStatus(CommunicationLogStatus.TRANSFERRED);
          }}
        />
      </Stack>
      <LvComplexTable
        rows={[]}
        columns={columnsData}
        leftPinnedColumns={['name']}
        activeFilters={filters}
        // filterMode="server"
        // checkboxSelection
        // onselectionChange={(selectedRows: any) => console.log(selectedRows)}
        // enableAdvanceNumberFiltering
        onFilterChange={(filters: any) => setFilters(filters)}
        rowHeight={48}
        headerHeight={50}
      />
    </Box>
  );
};

export default CommunicationLogs;
