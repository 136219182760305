import React, { useState } from 'react';
import { Box, Menu, Grid, Divider, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import { paperProps, radioButtonLabel, menuStyle, useStyles, paperPropsRight } from './date-filter-dropdown-styles';
import moment from 'moment';
import { getColor } from '../../colors';
import { CustomDatePicker } from '../form-elements/DatePicker';
import { OutlinedButton } from '../buttons/outlined-button';
import { ActionButton } from '../buttons/action-button';

export type DateFilterDropdownProps = {
  /**
   * menu heading- a string
   */
  menuHeading: string;
  /**
   * send a boolean value to display or hide the menu
   */
  open?: boolean;
  /**
   * send the element reference of the button on which click you want to open the dropdown menu
   */
  anchorEl?: HTMLElement | null;
  /**
   * function to execute on submit button
   */
  onSubmit: Function;
  /**
   * Function to execute on cancel
   */
  onCancel: Function;
  /**
   * setAnchorEl setter function to change state of open or close
   */
  setAnchorEl: Function;
  /**
   * search field name
   */
  field?: string;
  /**
   * value for date field
   */
  value?: string;
  /**
   * value for date range fields
   */
  rangeValue?: any;
  /**
   * boolean , if true will open pop-up on the right side.
   */
  openOnRight?: boolean;
  /**
   * boolean , only particular date will be searched and range is not displayed.
   */
  showOnlyContains?: boolean;
};

export function DateFilterDropdown({
  menuHeading = '',
  open = false,
  anchorEl = null,
  onSubmit = () => {},
  onCancel = () => {},
  setAnchorEl,
  field = '',
  value = '',
  rangeValue = { from: '', to: '' },
  openOnRight = false,
  showOnlyContains = false
}: DateFilterDropdownProps) {
  const [dateSearchOperator, setDateSearchOperator] = useState('is');
  const [dateValue, setDateValue] = useState(value);
  const [dateRangeValue, setDateRangeValue] = useState(rangeValue);
  const classes = useStyles();

  const handleSubmit = () => {
    const filterValues = {
      columnField: field,
      operator: dateSearchOperator,
      value: dateSearchOperator === 'is' ? dateValue : dateRangeValue
    };
    onSubmit && onSubmit(filterValues);
    setAnchorEl && setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      sx={{ ...menuStyle, height: showOnlyContains ? '227px' : null }}
      PaperProps={openOnRight ? paperPropsRight : paperProps}
      transformOrigin={{ horizontal: openOnRight ? 'left' : 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: openOnRight ? 'left' : 'right', vertical: 'bottom' }}
    >
      <Box>
        <Box sx={{ padding: '16px 20px 16px 20px' }}>
          <Typography fontSize="16px" fontWeight={600} lineHeight="24px" color={getColor('neutral', 900)}>
            {menuHeading}
          </Typography>
        </Box>
        <Divider sx={{ borderColor: getColor('border', 0) }} />
        <Box sx={{ padding: '0px 20px' }}>
          <Box sx={{ padding: '12px 0px 32px' }}>
            {showOnlyContains ? (
              <CustomDatePicker
                placeholder="dd/mm/yyyy"
                value={dateValue}
                alignCalenderIcon="right"
                onChange={(date: any) => {
                  setDateValue(moment(date).format('YYYY-MM-DD'));
                }}
                disabled={dateSearchOperator !== 'is'}
                showLabel={false}
              />
            ) : (
              <FormControl>
                <RadioGroup
                  aria-labelledby="operator-radio-button"
                  name="operator"
                  value={dateSearchOperator}
                  onChange={(e) => setDateSearchOperator(e.target.value)}
                >
                  <FormControlLabel
                    sx={radioButtonLabel}
                    value="is"
                    control={
                      <Radio
                        sx={{
                          '&, &.Mui-checked': {
                            color: getColor('primary', 700)
                          }
                        }}
                        size="small"
                      />
                    }
                    label="By Date"
                  />
                  <CustomDatePicker
                    placeholder="dd/mm/yyyy"
                    value={dateValue}
                    alignCalenderIcon="right"
                    onChange={(date: any) => setDateValue(moment(date).format('YYYY-MM-DD'))}
                    disabled={dateSearchOperator !== 'is'}
                    showLabel={false}
                  />
                  <FormControlLabel
                    sx={{
                      marginTop: '20px',
                      ...radioButtonLabel
                    }}
                    value="range"
                    control={
                      <Radio
                        sx={{
                          '&, &.Mui-checked': {
                            color: getColor('primary', 700)
                          }
                        }}
                        size="small"
                      />
                    }
                    label="By Range"
                  />
                  <Grid container bgcolor={getColor('shades', 0)}>
                    <Grid item display="flex" justifyContent="space-around">
                      <CustomDatePicker
                        alignCalenderIcon="right"
                        value={dateRangeValue.from}
                        placeholder="dd/mm/yyyy"
                        onChange={(date: any) =>
                          setDateRangeValue({ ...dateRangeValue, from: moment(date).format('YYYY-MM-DD') })
                        }
                        disabled={dateSearchOperator !== 'range'}
                        showLabel={false}
                      />
                      <div className={classes.minus}>-</div>
                      <CustomDatePicker
                        alignCalenderIcon="right"
                        value={dateRangeValue.to}
                        minDate={new Date(dateRangeValue.from)}
                        placeholder="dd/mm/yyyy"
                        onChange={(date: any) =>
                          setDateRangeValue({ ...dateRangeValue, to: moment(date).format('YYYY-MM-DD') })
                        }
                        disabled={dateSearchOperator !== 'range'}
                        showLabel={false}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            )}
          </Box>
          <Grid container bgcolor={getColor('shades', 0)}>
            <Grid item display="flex" justifyContent="space-around" marginTop="auto" marginBottom="14px">
              <Box sx={{ marginRight: '20px' }}>
                <OutlinedButton customStyles={{ width: '125px' }} onClick={onCancel}>
                  CANCEL
                </OutlinedButton>
              </Box>
              <Box>
                <ActionButton
                  customStyles={{ width: '125px' }}
                  disabled={
                    dateSearchOperator === 'is'
                      ? dateValue === '' || dateValue === 'Invalid date'
                      : Object.values(dateRangeValue).some((item) => !item || item === 'Invalid date') ||
                        moment(dateRangeValue.to).isBefore(dateRangeValue.from)
                  }
                  onClick={() => handleSubmit()}
                >
                  APPLY
                </ActionButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Menu>
  );
}
