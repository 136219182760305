import React, { useState, useEffect } from 'react';
import { Divider, Typography, Menu, Grid, Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { checkBox, checkBoxLabel, dropDownMenu, paperProps, paperPropsRight } from './checkbox-dropdown-styles';
import { getColor } from '../../colors';
import { OutlinedButton } from '../buttons/outlined-button';
import { ActionButton } from '../buttons/action-button';

export type CheckboxDropdownProps = {
  /**
   * Heading to be rendered in the checkbox dropdown
   */
  Heading?: string;
  /**
   * Data to be rendered in the checkbox dropdown
   */
  checkBoxData?: checkBoxData[];
  /**
   * a callback function that returns the data checkbox state
   */
  onChange?: Function;
  /**
   * a callback function that return the checked data
   */
  selectedData?: Function;
  /**
   * If true, the component is shown.
   */
  open?: boolean;
  /**
   * function for closing the component
   */
  setAnchorElm: Function;
  /**
   * An HTML element, or a function that returns one. It's used to set the position of the menu.
   */
  anchorElm?: HTMLElement | null;
  /**
   * width of the checkbox dropdown menu
   */
  width?: number | string;
  /**
   * if true enables the buttons
   */
  isButtonsEnabled?: boolean;
  /**
   * returns the object for the search
   */
  onSubmit?: Function;
  /**
   *
   */
  selectedValues?: Function;
  /**
   * search field name
   */
  field?: string;
  /**
   * if true dropdown acts as filter dropdown
   */
  isFilterDropdown?: boolean;
  /**
   * values of selected name
   */
  value?: any;
  /**
   *set specific max-height of the component in px, default will be 240px
   */
  maxHeight?: number;
  /**
   * boolean , if true will open pop-up on the right side.
   */
  openOnRight?: boolean;
};

export type checkBoxData = {
  id: number | string;
  name: string;
  isEnabled: boolean;
  isCheckboxDisabled?: boolean;
};

export function CheckboxDropdown(props: CheckboxDropdownProps) {
  const {
    Heading = 'Select Column',
    width = 285,
    checkBoxData = [],
    onChange,
    selectedData,
    anchorElm,
    setAnchorElm,
    open = false,
    isButtonsEnabled = false,
    isFilterDropdown = false,
    onSubmit,
    selectedValues,
    field = '',
    value = '',
    maxHeight = 285,
    openOnRight = false
  }: any = props;

  const [selectedValue, setSelectedValue] = useState<any[]>(checkBoxData);

  useEffect(() => {
    let updatedCheckboxData = checkBoxData;
    if (value !== '' || value?.length !== 0) {
      updatedCheckboxData.forEach((element: any) => {
        if (value.toString()?.includes(element?.name)) {
          element.isEnabled = true;
        } else {
          element.isEnabled = false;
        }
      });
    } else {
      let initialCheckBoxData = updatedCheckboxData.map((element: any, index: number) => {
        if (!element?.hasOwnProperty('isEnabled')) {
          return { ...element, isEnabled: false };
        }
        return element;
      });
      updatedCheckboxData = initialCheckBoxData;
    }
    setSelectedValue([...updatedCheckboxData]);
  }, [value, checkBoxData]);

  const handleChange = (e: any, item: any) => {
    let data = checkBoxData;
    if (isFilterDropdown) {
      let index = data.findIndex((ele: any) => ele.name === item.name);
      data.forEach((element: any) => {
        if (index === 0) {
          if (element.id === item.id) {
            element.isEnabled = e.target.checked;
          } else {
            element.isEnabled = false;
          }
        } else {
          if (element.id === item.id) {
            element.isEnabled = e.target.checked;
          }
          if (element.id === data[0].id) {
            element.isEnabled = false;
          }
        }
      });
    } else {
      data.forEach((element: any) => {
        if (element.id === item.id) {
          element.isEnabled = e.target.checked;
        }
      });
    }
    onChange && onChange(data);
    setSelectedValue([...data]);
    let checkedData = data.filter((ele: any) => ele.isEnabled);
    selectedData && selectedData(checkedData);
  };

  const handleSubmit = () => {
    let checkedListValue = selectedValue.filter((ele) => ele.isEnabled).map((data) => data.name);
    const filterValues = {
      columnField: field,
      operator: 'select',
      value: checkedListValue,
      fullObjectValues: selectedValue.filter((ele) => ele.isEnabled)
    };
    onSubmit && onSubmit(selectedValue);
    selectedValues && selectedValues(filterValues);
    setAnchorElm(null);
  };

  const borderStyle = {
    borderBottomLeftRadius: isButtonsEnabled ? '0px' : '8px',
    borderBottomRightRadius: isButtonsEnabled ? '0px' : '8px'
  };

  const buttonsBorderStyle = {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px'
  };

  return (
    <Menu
      PaperProps={openOnRight ? paperPropsRight : paperProps}
      transformOrigin={{ horizontal: openOnRight ? 'left' : 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: openOnRight ? 'left' : 'right', vertical: 'bottom' }}
      anchorEl={anchorElm}
      open={open}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      onClose={() => setAnchorElm(null)}
      sx={{
        '& .MuiPaper-root': { width: width, ...dropDownMenu, maxHeight: maxHeight, ...borderStyle }
      }}
    >
      <div style={{ height: '56px', display: 'flex', alignItems: 'center' }}>
        <Typography fontSize={16} fontWeight={600} marginLeft="20px" color={getColor('neutral', 900)}>
          {Heading}
        </Typography>
      </div>
      <Divider />
      <FormGroup
        style={{
          marginLeft: '20px',
          overflow: 'auto',
          maxHeight: `${maxHeight - 57}px`,
          flexWrap: 'inherit'
        }}
      >
        {isButtonsEnabled
          ? selectedValue.map((item) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    checked={item.isEnabled}
                    value={item.isEnabled}
                    disabled={item.isCheckboxDisabled}
                    onChange={(e) => handleChange(e, item)}
                    size="small"
                    sx={checkBox}
                  />
                }
                label={item.name}
                sx={checkBoxLabel}
              />
            ))
          : checkBoxData.map((item: any) => (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    checked={item.isEnabled}
                    value={item.isEnabled}
                    disabled={item.isCheckboxDisabled}
                    onChange={(e) => handleChange(e, item)}
                    size="small"
                    sx={checkBox}
                  />
                }
                label={item.name}
                sx={checkBoxLabel}
              />
            ))}
      </FormGroup>
      {isButtonsEnabled && (
        <Grid container bgcolor={getColor('shades', 0)} sx={buttonsBorderStyle}>
          <Grid
            item
            display="flex"
            justifyContent="space-around"
            marginTop="25px"
            marginBottom="20px"
            marginLeft="20px"
          >
            <Box sx={{ marginRight: '20px' }}>
              <OutlinedButton customStyles={{ width: '112px' }} onClick={() => setAnchorElm(null)}>
                CANCEL
              </OutlinedButton>
            </Box>
            <Box>
              <ActionButton
                disabled={isFilterDropdown ? !selectedValue.some((item) => item.isEnabled) : false}
                customStyles={{ width: '112px' }}
                onClick={handleSubmit}
              >
                APPLY
              </ActionButton>
            </Box>
          </Grid>
        </Grid>
      )}
    </Menu>
  );
}
