import Backers from '../../app/backers/components/Backers';
import IndividualBacker from '../../app/backers/components/IndividualBacker';
import Dashboard from '../../app/dashboard/components/Dashboard';
import CommunicationLogs from '../../app/deals/components/CommunicationLogs';
import Deals from '../../app/deals/components/Deals';
import IndividualDeal from '../../app/deals/components/IndividualDeal';
import { USER_ROLES } from '../../constants/appConstants';
import ComponentsExample from '../../ui-library/components/ComponentsExample';

export const AdminRoutes = () => [
  {
    label: 'DASHBOARD',
    value: 'dashboard',
    icon: 'fa-regular fa-grid-2',
    link: 'dashboard',
    component: <Dashboard />,
    order: 1,
    roles: [USER_ROLES.ADMIN]
  },
  {
    label: 'BACKERS',
    value: 'backers',
    icon: 'fa-regular fa-square-poll-vertical',
    link: 'backers',
    component: <Backers />,
    order: 1,
    roles: [USER_ROLES.ADMIN]
  },
  {
    link: 'backers/:id',
    value: 'backers',
    component: <IndividualBacker />,
    order: 1,
    roles: [USER_ROLES.ADMIN]
  },
  {
    label: 'DEALS',
    value: 'deals',
    icon: 'fa-sharp fa-light fa-chart-pie-simple',
    link: 'deals',
    component: <Deals />,
    order: 1,
    roles: [USER_ROLES.ADMIN]
  },
  {
    link: 'deals/:id',
    value: 'deals',
    component: <IndividualDeal />,
    order: 1,
    roles: [USER_ROLES.ADMIN]
  },
  {
    link: 'deals/:id/communication-logs',
    value: 'deals',
    component: <CommunicationLogs />,
    order: 1,
    roles: [USER_ROLES.ADMIN]
  },
  {
    label: 'SETTINGS',
    value: 'settings',
    icon: 'fa-light fa-gear',
    link: 'settings',
    component: <Dashboard />,
    order: 1,
    roles: [USER_ROLES.ADMIN]
  },
  {
    label: 'LOGOUT',
    value: 'lv-components',
    icon: 'fa-regular fa-circle-question',
    link: 'lv/component',
    component: <ComponentsExample />,
    order: 1,
    roles: [USER_ROLES.ADMIN]
  }
  // {
  //   label: 'DASHBOARD',
  //   value: 'dashboard',
  //   icon: 'https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg',
  //   link: 'dashboard',
  //   component: <Home />,
  //   order: 1,
  //   roles: [USER_ROLES.ADMIN]
  // },
  // {
  //   label: 'My Startup',
  //   value: 'my-startup',
  //   icon: 'https://dsjvxb1plg419.cloudfront.net/v2.0/NavDashboardIcon.svg',
  //   link: 'my/startup',
  //   component: <MyStartup />,
  //   order: 1,
  //   roles: [USER_ROLES.ADMIN]
  // },
];
