import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Menu,
  Grid,
  Divider,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { getColor } from '../../colors';
import { OutlinedButton } from '../buttons/outlined-button';
import { ActionButton } from '../buttons/action-button';
import { paperProps, radioButtonLabel, useStyles, menuStyle, paperPropsRight } from './number-filter-dropdown-styles';

export type NumberFilterDropdownProps = {
  /**
   * menu heading- a string
   */
  menuHeading: string;
  /**
   * send a boolean value to display or hide the menu
   */
  open?: boolean;
  /**
   * send the element reference of the button on which click you want to open the dropdown menu
   */
  anchorEl?: HTMLElement | null;
  /**
   * function to execute on submit button
   */
  onSubmit?: Function;
  /**
   * Function to execute on cancel
   */
  onCancel?: Function;
  /**
   * setAnchorEl setter function to change state of open or close
   */
  setAnchorEl: Function;
  /**
   * search field name
   */
  field?: string;
  /**
   * value for the textfield
   */
  value?: string;
  /**
   * boolean , if true will open pop-up on the right side.
   */
  openOnRight?: boolean;
};

export function NumberFilterDropdown({
  menuHeading = '',
  open = false,
  anchorEl = null,
  onSubmit = () => {},
  onCancel = () => {},
  setAnchorEl,
  field = '',
  value = '',
  openOnRight = false
}: NumberFilterDropdownProps) {
  const [operatorValue, setOperatorValue] = useState('=');
  const [inputValue, setInputValue] = useState<string>(value);
  const classes = useStyles();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const options = [
    { name: 'Equals', value: '=' },
    { name: 'Greater than', value: '>' },
    { name: 'Less than', value: '<' }
  ];

  const handleSubmit = () => {
    const filterValues = {
      columnField: field,
      operator: operatorValue,
      value: inputValue
    };
    onSubmit && onSubmit(filterValues);
    setAnchorEl && setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      sx={menuStyle}
      PaperProps={openOnRight ? paperPropsRight : paperProps}
      transformOrigin={{ horizontal: openOnRight ? 'left' : 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: openOnRight ? 'left' : 'right', vertical: 'bottom' }}
    >
      <Box>
        <Box sx={{ padding: '16px 20px 16px 20px' }}>
          <Typography fontSize="16px" fontWeight={600} lineHeight="24px" color={getColor('neutral', 900)}>
            {menuHeading}
          </Typography>
        </Box>
        <Divider sx={{ borderColor: getColor('border', 0) }} />
        <Box sx={{ padding: '0px 20px' }}>
          <Box sx={{ padding: '12px 0px 32px', display: 'grid' }}>
            <FormControl sx={{ marginBottom: '20px' }}>
              <RadioGroup
                row
                aria-labelledby="operator-radio-button"
                name="operator"
                value={operatorValue}
                onChange={(e) => setOperatorValue(e.target.value)}
              >
                {options &&
                  options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      sx={radioButtonLabel}
                      value={option.value}
                      control={
                        <Radio
                          sx={{
                            '&, &.Mui-checked': {
                              color: getColor('primary', 700)
                            }
                          }}
                          size="small"
                        />
                      }
                      label={option.name}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
            <TextField
              className={classes.textField}
              placeholder="Type here..."
              type="number"
              fullWidth
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              autoFocus
            />
          </Box>
          <Grid container bgcolor={getColor('shades', 0)} justifyContent="center">
            <Grid item display="flex" justifyContent="space-around" marginTop="auto" marginBottom="21px">
              <Box sx={{ marginRight: '20px' }}>
                <OutlinedButton customStyles={{ width: '112px' }} onClick={onCancel}>
                  CANCEL
                </OutlinedButton>
              </Box>
              <Box>
                <ActionButton customStyles={{ width: '112px' }} disabled={!inputValue} onClick={() => handleSubmit()}>
                  APPLY
                </ActionButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Menu>
  );
}
