import React from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getColor } from '../../colors';
import { LVThemeProvider } from '../theme/lvtheme';

export type NavigationButtonProps = {
  /**
   * title text for button .
   */
  title?: string;
  /**
   *function definition for on click routing.
   */
  onClick: () => void;
  /**
   * prop to hide the button
   */
  hidden?: boolean;
};

const useStyles = makeStyles({
  root: {
    color: getColor('accent', 0),
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  text: { textTransform: 'capitalize', paddingRight: '5px' },
  icon: {
    fontSize: '14px !important'
  }
});
export function NavigationButton({ title = 'view all', onClick, hidden = false }: NavigationButtonProps) {
  const classes = useStyles();
  const handleClick = () => {
    onClick && onClick();
  };
  if (hidden) {
    return <div />;
  }
  return (
    <div>
      <LVThemeProvider>
        <Button className={classes.root} onClick={handleClick} disableRipple>
          <Typography variant="heading_05_medium" className={classes.text} color={getColor('accent', 0)}>
            {title}
          </Typography>
          <ArrowForwardIosIcon className={classes.icon} />
        </Button>
      </LVThemeProvider>
    </div>
  );
}
