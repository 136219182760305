import { useEffect } from 'react';

const CustomProgressBar = ({ lvRaisedPercentage, receivedPercentage }) => {
  const progressColor = (lvRaisedPercentageData, receivedPercentageData) => {
    let moneyReceivedColor = '';
    let lvRaisedColor = '';
    if (lvRaisedPercentageData > receivedPercentageData) {
      moneyReceivedColor = receivedPercentageData
        ? `#0CBA88 ${receivedPercentageData}%, #FF6727 ${receivedPercentageData}%, `
        : '';
      lvRaisedColor = lvRaisedPercentageData ? `#FF6727 ${lvRaisedPercentageData}%, ` : '';
    } else {
      moneyReceivedColor = receivedPercentageData
        ? `#FF6727 ${lvRaisedPercentageData}%, #0CBA88 ${lvRaisedPercentageData}%, `
        : '';
      lvRaisedColor = receivedPercentageData ? `#0CBA88 ${receivedPercentageData}%, ` : '';
    }

    return moneyReceivedColor + lvRaisedColor;
  };

  useEffect(() => {}, [lvRaisedPercentage, receivedPercentage]);

  return (
    <div className="progress" style={{ height: '20px', position: 'relative', borderRadius: '2px', overflow: 'hidden' }}>
      <div
        className="progress-bar"
        style={{
          background: `linear-gradient(to right,${progressColor(
            parseFloat(lvRaisedPercentage),
            parseFloat(receivedPercentage)
          )} #e9ecef 0)`,
          position: 'absolute',
          width: '100%',
          height: '100%'
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '30px',
          zIndex: 1
        }}
      >
        {``}
      </div>
    </div>
  );
};

export default CustomProgressBar;
