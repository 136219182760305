import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getColor } from '../../colors';

export type ErrorCardProps = {
  /**
   * here you can pass text JSX , it will be beside the error icon
   */
  errorName: ReactNode | string;

  /**
   *set width of this component
   */
  width?: string;

  /**
   *set background color
   */
  backgroundColor?: string;

  /**
   *if this is true then the error icon color will be red.
   */
  redIcon?: boolean;

  /**
   *Accept border radius for card container.
   */
  borderRadius?: number;

  /**
   *Accept padding for left of error icon.
   */
  leftPaddingErrorIcon?: number;
};

export function ErrorCard({
  width = 'auto',
  errorName,
  backgroundColor = getColor('secondary', 700),
  redIcon = false,
  borderRadius = 4,
  leftPaddingErrorIcon = 10
}: ErrorCardProps) {
  const useStyles = makeStyles({
    errContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      width: width,
      borderRadius: borderRadius,
      backgroundColor: backgroundColor,
      paddingLeft: leftPaddingErrorIcon
    },
    errImg: {
      width: 16.36,
      height: 15
    },
    errMessage: {
      color: getColor('shades', 0),
      fontWeight: 500,
      fontSize: 13
    }
  });

  const classes = useStyles();
  return (
    <Box height={'44px'} className={classes.errContainer}>
      <img
        src={
          redIcon
            ? 'https://dsjvxb1plg419.cloudfront.net/v2.0/Error.svg'
            : 'https://dsjvxb1plg419.cloudfront.net/v2.0/ErrorWhite.svg'
        }
        alt="error"
        className={classes.errImg}
      />
      <Box className={classes.errMessage}>{errorName}</Box>
    </Box>
  );
}
