import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field } from 'formik';
import moment from 'moment';
import { getColor } from '../../colors';
import { LVThemeProvider } from '../theme/lvtheme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles({
  datePickerLeftStyles: {
    '& .MuiInputBase-root': {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    '& .MuiInputAdornment-root': {
      marginLeft: '0px'
    }
  },
  datePickerRightStyles: {
    '& .MuiInputAdornment-root': {
      marginLeft: '0px'
    }
  },
  bottomMargin: {
    marginBottom: '30px !important'
  },
  root: {
    '& .MuiInputBase-root': {
      caretColor: 'transparent',
      cursor: 'pointer'
    },
    '& label': {
      top: '-4px',
      fontSize: '14px',
      transform: 'translate(14px, 14px) scale(1)'
    },
    '&:hover label': {
      color: `${getColor('primary', 700)}`
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -4px) scale(0.86)'
    },
    '& label.Mui-focused': {
      color: `${getColor('primary', 700)}`
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: `${getColor('primary', 700)} !important`
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: getColor('primary', 700),
      transition: 'none'
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: getColor('primary', 700)
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${getColor('primary', 700)}`
      }
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      '& fieldset': {
        borderColor: `${getColor('primary', 700)} !important`
      },
      '& input': {
        '-webkit-text-fill-color': getColor('border', 200)
      },
      backgroundColor: getColor('background', 50)
    }
  }
});

export type DateFormat = 'dd/MM/yyyy' | 'MM/dd/yyyy' | 'yyyy/MM/dd' | 'yyyy/dd/MM' | 'dd MMM yyyy';

export type Mask = '__/__/____' | '____/__/__';

export type CalenderIconAlign = 'left' | 'right';

export type DatePickerProps = {
  /**
   * Placeholder for input field
   */
  placeholder?: string;

  /**
   * Date icon color
   */
  iconColor?: string;

  /**
   * Default Date
   */
  defaultValue?: null | string | Date;

  /**
   * masking format (enum: '__/__/____' | '____/__/__')
   */
  mask?: string;

  /**
   * Date format (enum: 'dd/MM/yyyy' | 'MM/dd/yyyy' | 'yyyy/MM/dd' | 'yyyy/dd/MM')
   */
  dateFormat?: DateFormat;

  /**
   * onChange Date input
   */
  onChange?: Function;

  /**
   * calender icon left/right
   */
  alignCalenderIcon?: CalenderIconAlign;

  /**
   *is this is true , it will make the field mandatory and put an asterisk on the label name.
   */
  required?: boolean;

  /**unique identifier for input field */
  id?: string;

  /**unique name for input field */
  name?: string;

  /**implicit prop from formik. Dont have to pass as prop */
  form?: any;

  value?: any;
  /**
   *here you can set error(boolean) as true to show error , it will turn highlightborder & labelName color to red
   */
  error?: boolean;

  disabled?: boolean;

  /**props needed in case if formik fieldarray */
  index?: number;

  fieldArray?: boolean;

  formName?: string;

  fieldName?: string;

  /**prop to control min date to be enabled in calendar in DateIOType */
  minDate?: any;

  /**prop to control max date to be enabled in calendar in DateIOType */
  maxDate?: any;
  /**
   *height of the component.
   */
  height?: string | number;
  /**
   *boolean to show default cursor style in advanced filtering in table.
   */
  defaultCursor?: boolean;
  /**
   *boolean to don't show the label (for table filters only)
   */
  showLabel?: boolean;
  /**
   *helper text for the date picker component.
   */
  helperText?: any;
  /**
   *flag to now show the calendar.
   */
  avoidDatePicker?: any;
  /** ref of the component */
  ref?: any;
  /**
   *  callback function to be triggered on click of the input field
   */
  openAdvancedFilter?: Function | null;
};

export const CustomDatePicker = ({
  placeholder = 'Select',
  iconColor = getColor('primary', 700),
  defaultValue = null,
  mask = '__/__/____',
  dateFormat = 'dd/MM/yyyy',
  onChange,
  alignCalenderIcon = 'left',
  name = '',
  id = '',
  value = null,
  error,
  form,
  required,
  disabled = false,
  fieldArray = false,
  fieldName = '',
  index = 0,
  formName = '',
  minDate,
  maxDate,
  height,
  defaultCursor = false,
  showLabel = true,
  helperText,
  avoidDatePicker = false,
  openAdvancedFilter = null,
  ref = null
}: DatePickerProps) => {
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleChange = (selectedDate: any) => {
    const value = selectedDate ? new Date(moment(selectedDate).format('YYYY-MM-DD') + 'T10:30:00.000Z') : null;
    if (form) {
      if (fieldArray) {
        const newTouched = form.touched[formName] ?? [];
        newTouched[index] = { ...(newTouched[index] ?? {}), [fieldName]: true };
        form.setTouched({ ...form.touched, [formName]: newTouched });
      } else {
        form?.setTouched({ ...form.touched, [id]: true });
      }
      form?.setFieldValue(id, value);
    }
    onChange && onChange(value);
  };

  const classes = useStyles();

  const getErrorMessage = () => {
    let errorMessage = '';
    if (form) {
      if (fieldArray && error && form?.touched?.[formName]?.[index]?.[fieldName]) {
        errorMessage = form?.errors?.[formName]?.[index]?.[fieldName];
      } else if (error && form?.touched[id]) {
        errorMessage = form?.errors[id];
      }
    }

    setInvalidDate(!!errorMessage);

    return errorMessage;
  };

  const calendarIcon = () => (
    <img
      src="https://dsjvxb1plg419.cloudfront.net/v2.0/CalendarIcon.svg"
      alt="icon"
      className="calendarIcon"
      height="15px"
      width="14px"
    />
  );

  return (
    <Box
      className={` ${classes.root} ${
        alignCalenderIcon === 'left' ? classes.datePickerLeftStyles : classes.datePickerRightStyles
      }  ${invalidDate && classes.bottomMargin} `}
      sx={{
        '& .MuiInputBase-root': {
          height: height ? height : 40
        }
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MUIDatePicker
          format={dateFormat as string}
          disabled={disabled}
          open={!(avoidDatePicker || disabled) && datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          value={defaultValue ?? value}
          onChange={(date) => handleChange(date)}
          minDate={minDate}
          maxDate={maxDate}
          views={['year', 'month', 'day']}
        />
      </LocalizationProvider>
      {helperText && (
        <Typography variant="caption_regular" sx={{ position: 'relative', top: '5px' }}>
          <span style={{ color: getColor('neutral', 600) }}>{helperText}</span>
        </Typography>
      )}
    </Box>
  );
};
export function DatePicker(props: any) {
  return (
    <LVThemeProvider>
      <Field component={CustomDatePicker} {...props} />
    </LVThemeProvider>
  );
}
