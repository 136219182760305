import { Box } from '@mui/material';
import React, { FC } from 'react';
import { CardContainer } from '../../../ui-library/components/card/card-container';
import CustomProgressBar from '../../../common/components/ProgressBar';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { SectionHeader } from '../../../ui-library/components/header/section-header';

const LiveDeal: FC<any> = ({ dealData }) => {
  const cardOptions = [
    {
      label: 'Launched',
      spacing: 3,
      value: 'LetsVenture'
    },
    {
      label: `Backers(${dealData?.name})`,
      spacing: 3,
      value: 'LetsVenture Private Limited'
    },
    {
      label: 'Scheme Doc',
      spacing: 3,
      renderer: <CloudDownloadOutlined />
    },
    {
      label: 'Lead Scheme Doc',
      spacing: 3,
      renderer: <CloudDownloadOutlined />
    }
  ];

  const cardOptions2 = [
    {
      label: 'Transferred to Startup',
      spacing: 3,
      value: '₹ 40 Lakhs'
    },
    {
      label: 'Transfer Date',
      spacing: 3,
      value: 'Jul 20, 2024'
    }
  ];
  return (
    <Box className="card_border text-left" mb={3}>
      <Box gap={2} display="flex" alignItems="center" padding="1.875rem" pb={0}>
        <img
          src="https://fastly.picsum.photos/id/428/200/200.jpg?hmac=t9FYhwylg9uE-Y2lJluz7aIxlV_-R2FJQZV8UpoOu7M"
          style={{ height: 25, aspectRatio: 1, borderRadius: '3px' }}
        />
        <SectionHeader>Startup Name</SectionHeader>
      </Box>
      <CardContainer options={cardOptions} />
      <Box px={'1.875rem'} mb={'1rem'}>
        <CustomProgressBar lvRaisedPercentage={50} receivedPercentage={90} />
      </Box>
      <CardContainer options={cardOptions2} />
    </Box>
  );
};

export default LiveDeal;
