import React, { ReactNode } from 'react';
import { Box, Drawer, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getColor } from '../../colors';
import { OutlinedButton } from '../buttons/outlined-button';
import { ActionButton } from '../buttons/action-button';

const useStyles = makeStyles({
  drawerRoot: { width: '32%' },
  drawerHeader: {
    padding: '1rem 1.25rem',
    backgroundColor: getColor('primary', 50)
  },
  drawerContainer: {
    padding: '1.25rem',
    height: 'calc(100vh - 20px)',
    position: 'relative',
    overflowY: 'scroll',
    marginBottom: '5rem'
  },
  submitContainer: {
    '& .MuiBadge-root': {
      width: '100%',
      paddingLeft: '12px'
    }
  }
});

const handleCancelButton = {
  width: '100%',
  px: 0,
  marginRight: '6px',
  '&:hover': {
    background: getColor('primary', 50),
    borderColor: getColor('primary', 700)
  }
};

const handleSubmitButton = {
  width: '100%',
  px: 0,
  backgroundColor: getColor('action_button', 200),
  '&:hover': {
    backgroundColor: getColor('action_button', 100),
    borderColor: getColor('action_button', 0),
    boxShadow: 'none'
  }
};

export type SideDrawerProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;
  /**
   * open/close the drawer
   */
  openDrawer?: boolean;
  /**
   * render the header component in drawer
   */
  header?: ReactNode;
  /**
   * show/hide cancel button
   */
  cancelButton?: boolean;
  /**
   * function to handle cancel button
   */
  onClickCancel?: Function;
  /**
   *  cancel button text
   */
  cancelButtonText?: string;
  /**
   * show/hide save button
   */
  saveButton?: boolean;
  /**
   * function to handle save button
   */
  onClickSave?: Function;
  /**
   * save button text
   */
  saveButtonText?: string;
  /**
   *  button type as submit
   */
  submitButton?: string;
};

export function SideDrawer({
  children,
  openDrawer,
  header,
  cancelButton,
  cancelButtonText,
  onClickCancel,
  saveButton,
  onClickSave,
  saveButtonText
}: SideDrawerProps) {
  const classes = useStyles();
  return (
    <Box id="drawer-container">
      <Drawer classes={{ paper: classes.drawerRoot }} anchor="right" open={openDrawer}>
        <div className={classes.drawerHeader}>{header}</div>
        <Box className={classes.drawerContainer} display="flex" justifyContent="space-between" flexDirection="column">
          <div>{children}</div>
        </Box>
        <Box sx={{ padding: '20px' }}>
          <Grid container justifyContent={'end'}>
            <Grid item xs={6}>
              {cancelButton && (
                <OutlinedButton customStyles={{ ...handleCancelButton }} onClick={onClickCancel}>
                  {cancelButtonText ? cancelButtonText : 'CANCEL'}
                </OutlinedButton>
              )}
            </Grid>
            <Grid item xs={6}>
              {saveButton && (
                <div className={classes.submitContainer}>
                  <ActionButton customStyles={{ ...handleSubmitButton }} type="submit" onClick={onClickSave}>
                    {saveButtonText ? saveButtonText : 'SAVE CHANGES'}
                  </ActionButton>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}
