import { makeStyles } from '@mui/styles';
import { getColor } from '../../colors';

export const useStyles = makeStyles({
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px',
    alignItems: 'center'
  },
  alert: {
    paddingLeft: '8px',
    display: 'flex',
    backgroundColor: getColor('primary', 50),
    alignItems: 'center',
    height: '30px',
    borderRadius: '4px'
  },
  alertText: {
    color: getColor('primary', 900),
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px'
  },
  alertTextValue: {
    color: getColor('primary', 900),
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px'
  },
  resetButton: {
    cursor: 'pointer',
    padding: '4px 12px'
  },
  reset: {
    textDecorationLine: 'underline',
    color: getColor('primary', 700),
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 500
  }
});
