import { Stack } from '@mui/material';
import React, { FC } from 'react';

const ActionContainer: FC<any> = ({ children }) => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={1} width="100%" margin="auto" height="100%">
      {children}
    </Stack>
  );
};

export default ActionContainer;
