import { GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { CustomGridFilterItem } from './active-filters-panel';

const dateFilterOperators: GridFilterOperator[] = [
  {
    value: 'range',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) return null;
      if (!filterItem.value.from || !filterItem.value.to) return null;

      return ({ value }) => {
        return moment(value).isBetween(filterItem.value.from, filterItem.value.to, 'day', '[]');
      };
    }
  },
  {
    value: 'is',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.value) return null;

      return ({ value }) => {
        return moment(value).isSame(filterItem.value, 'day');
      };
    }
  }
];

const selectFilterOperators: GridFilterOperator[] = [
  {
    value: 'select',
    getApplyFilterFn: (filterItem: CustomGridFilterItem) => {
      if (!filterItem.value && !filterItem.fullObjectValues) return null;

      return ({ value }) => {
        if (filterItem.fullObjectValues) {
          const valuesToCheck = filterItem.fullObjectValues.map((item) => item.value);
          if (valuesToCheck.includes('')) return true; // show all rows
          return filterItem.fullObjectValues.map((item) => item.value).includes(value); // show matched rows
        }
        if (filterItem.value) {
          const valuesToCheck = filterItem?.value.map((item: any) => item.toString().toLowerCase());
          if (valuesToCheck.includes('') || valuesToCheck.find((item: any) => item.includes('all'))) return true;
          return valuesToCheck.includes(value.toLowerCase());
        }
        return null;
      };
    }
  }
];

export { dateFilterOperators, selectFilterOperators };
