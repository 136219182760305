import { makeStyles } from '@mui/styles';
import { getColor } from '../../colors';

export const useStyles = makeStyles({
  textField: {
    '& .MuiInputLabel-outlined': {
      color: '#888888',
      transform: 'translate(14px, 10px) scale(0.93)',
      fontFamily: 'Work Sans'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: getColor('primary', 700),
      transform: 'translate(12px, -6px) scale(0.65)'
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: getColor('primary', 700)
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: getColor('primary', 700)
    },
    '& .MuiFormControl-root': {
      border: '1px solid #818283'
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
      '& fieldset': {
        border: '1px solid #818283'
      },
      '&:hover fieldset': {
        borderColor: getColor('primary', 700)
      },
      '&.Mui-focused fieldset': {
        borderColor: getColor('primary', 700)
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
      fontFamily: 'Work Sans'
    }
  }
});

export const radioButtonLabel = {
  color: getColor('neutral', 900),
  fontFamily: 'Work Sans',
  marginRight: '30px',
  '& .MuiRadio-root': {
    padding: '0px 8px 0px 11px'
  },
  '& .MuiTypography-root': {
    fontSize: '14px'
  }
};

export const menuStyle = {
  '& .MuiPaper-root': {
    borderRadius: '8px',
    '& .MuiList-root': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  }
};

export const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};
export const paperPropsRight = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};
