import React, { ReactNode } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getColor } from '../../colors';
import { LVThemeProvider } from '../theme/lvtheme';

export type VerticalTabPanelProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: React.ReactNode;
  index?: number;
  value?: number;
};

export type VerticalTabProps = {
  /**
   * An array of object containing objects(TABS) in the format {
    name: string,
    value: any,
    content: ReactNode,
    disabled?: boolean
  }
   */
  tabs: {
    name: string;
    value: any;
    content: ReactNode;
    disabled?: boolean;
  }[];
};

function TabPanel(props: VerticalTabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && <Box sx={{ backgroundColor: getColor('background', 300) }}>{children}</Box>}
    </div>
  );
}

export function VerticalTabs({ tabs }: VerticalTabProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <LVThemeProvider>
      <Box
        sx={{
          bgcolor: 'background.paper',
          display: 'flex',
          height: 'fit-content',
          width: '100%'
        }}
      >
        <Tabs
          variant="scrollable"
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', minHeight: 'inherit', width: '191px', overflow: 'visible' }}
          TabIndicatorProps={{
            style: { background: '#3A5277', left: '0', width: '4px', borderRadius: '4px 0px 0px 4px' }
          }}
        >
          {tabs.length &&
            tabs.map((tab, index) => (
              <Tab
                key={index}
                sx={{ backgroundColor: index === value ? getColor('background', 300) : '', textAlign: 'start' }}
                label={
                  <div style={{ height: '35px', display: 'block', justifyContent: 'center', alignSelf: 'self-start' }}>
                    <Typography
                      variant="heading_05_medium"
                      color={tabs[index].disabled === true ? '' : getColor('neutral', 900)}
                      style={{ fontSize: 13, fontWeight: 600, marginBottom: '3px', width: 'max-content' }}
                    >
                      {tabs[index].name}
                    </Typography>
                    <Typography
                      variant="heading_06_medium"
                      color={tabs[index].disabled ? '' : getColor('neutral', 700)}
                      style={{ fontSize: 13 }}
                    >
                      {tabs[index].value}
                    </Typography>
                  </div>
                }
                disabled={tab.disabled}
              />
            ))}
        </Tabs>

        {tabs.length &&
          tabs.map((tab, index) => (
            <TabPanel value={value} index={index} key={index}>
              {tabs[index].content}
            </TabPanel>
          ))}
      </Box>
    </LVThemeProvider>
  );
}
