import { useState } from 'react';
import { getColor } from '../../colors';
import { ActionButton } from './action-button';

type buttonDataObject = {
  title: string;
};

export type ToggleButtonsProps = {
  // an array of objects of all the buttons
  buttons: buttonDataObject[];

  //style for the active button
  activeAButtonStyle?: object;

  //style for the deactivated button
  disableButtonStyle?: object;

  //function to be invoked onClick
  onClick?: Function;

  //array of indexes of buttons which needs to be disabled
  disabled?: number[];

  //index of the active button
  activeButtonIndex: number;

  //style for outer container
  containerStyle?: object;
};

export function ToggleButtons({
  buttons = [],
  activeAButtonStyle = {
    backgroundColor: getColor('primary', 700),
    color: getColor('shades', 0),
    fontSize: '13px',
    fontWeight: '500',
    height: '1.81rem',
    '&:hover': {
      backgroundColor: getColor('primary', 700),
      boxShadow: 'none'
    }
  },
  disableButtonStyle = {
    backgroundColor: 'transparent',
    color: getColor('primary', 700),
    fontSize: '13px',
    fontWeight: '500',
    height: '1.81rem',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  disabled = [],
  activeButtonIndex,
  onClick,
  containerStyle = {
    padding: '4px',
    backgroundColor: getColor('primary', 50),
    borderRadius: '4px'
  }
}: ToggleButtonsProps) {
  const [activeIndex, setActiveIndex] = useState<number>(activeButtonIndex);
  const handleChange = (index: number) => {
    setActiveIndex(index);
    onClick && onClick();
  };
  return (
    <div style={containerStyle}>
      {buttons.map((button, index) => {
        let buttonDisabled = false;
        if (disabled.length) {
          if (disabled.includes(index)) {
            buttonDisabled = true;
          }
        }
        let customStyle = index === activeIndex ? activeAButtonStyle : disableButtonStyle;
        return (
          <span key={index}>
            <ActionButton
              variant={index === activeIndex ? 'contained' : 'text'}
              customStyles={customStyle}
              disabled={buttonDisabled}
              onClick={() => handleChange(index)}
            >
              {button.title}
            </ActionButton>
          </span>
        );
      })}
    </div>
  );
}
