import React, { ReactNode, useState, useEffect } from 'react';
import { Collapse, Typography } from '@mui/material';
import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import { getColor } from '../../colors';
import { collapseIconStyle } from './collapse-component-styles';

export type CollapseComponentProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;

  /**
   * Show or hide the child component
   */
  shouldShow?: boolean;

  /**
   * Text for collapse toggle component
   */
  text?: string;

  /**
   * Background color for collapse toggle component
   */
  bgColor?: string;

  /**
   * Custom style for the main div component
   */
  collapseCustomStyle?: object;

  /**
   * Custom style for the Collapse toggle component
   */
  customStyle?: object;

  /**
   * Custom style for the childComponent
   */
  childCompStyle?: object;

  /**
   * onClick on collapse toggle
   */
  handleClick?: Function;
};

export function CollapseComponent({
  children,
  shouldShow = true,
  text = 'Additional Terms',
  bgColor = getColor('primary', 50),
  customStyle = {},
  collapseCustomStyle = {},
  childCompStyle = {},
  handleClick = () => {}
}: CollapseComponentProps) {
  const [show, setShow] = useState(shouldShow ?? true);

  const onClick = () => {
    setShow(!show);
    handleClick && handleClick(show);
  };

  return (
    <div style={{ width: '100%', padding: 0, margin: 0, ...customStyle }}>
      <Typography
        onClick={onClick}
        sx={{
          cursor: 'pointer',
          backgroundColor: bgColor,
          padding: '8px 16px',
          display: 'flex',
          justifyItems: 'flex-start',
          ...collapseCustomStyle
        }}
      >
        {show ? <RemoveOutlined sx={collapseIconStyle} /> : <AddOutlined sx={collapseIconStyle} />}
        <Typography
          sx={{ marginLeft: '5px', fontFamily: 'Work Sans', fontWeight: 500, fontSize: '14px', lineHeight: '21px' }}
        >
          {text}
        </Typography>
      </Typography>
      <Collapse sx={{ ...childCompStyle }} in={show}>
        {children}
      </Collapse>
    </div>
  );
}
