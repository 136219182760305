// import { Auth0Provider } from "@auth0/auth0-react";
// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App";
// import PostHogManager from "./analytics/postHogManager";
// import "./index.css";
// import reportWebVitals from "./reportWebVitals";
// import * as Sentry from '@sentry/browser';

// Sentry.init({
//   dsn: 'https://2177427d85934a3b854f648d7673e18c@o1396885.ingest.sentry.io/6721630',
//   // Performance monitoring options
//   tracesSampleRate: 1.0 // Adjust the sample rate as needed
// });

// PostHogManager.initiatePostHog();

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Auth0Provider
//       domain={process.env.REACT_APP_GLASSBOARD_AUTH0_DOMAIN}
//       clientId={process.env.REACT_APP_GLASSBOARD_AUTH0_CLIENT_ID}
//       authorizationParams={{
//         audience: process.env.REACT_APP_GLASSBOARD_AUTH0_AUDIENCE,
//         redirect_uri: `${window.location.origin}/callback`,
//       }}
//       onRedirectCallback={`${window.location.origin}/callback`}
//       useRefreshTokens
//       useRefreshTokensFallback
//       cacheLocation="localstorage"
//     >
//       <App />
//     </Auth0Provider>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
