import { Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { ActionButton } from "@trica-equity/ui-library.buttons.action-button";
// import { Topbar } from "@trica-equity/ui-library.navs.topbar";
// import CustomFooter from '../customFooter';
// import useStyles from "./styles";

const PageNotFound = () => {
  //   const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <Typography>Sorry, the page you are looking for doesn&apos;t exist</Typography>
      {/* <div className={classes.mainBody}>
        <Topbar position="relative">
          <img
            src="https://dsjvxb1plg419.cloudfront.net/trica-equity-logo-01.svg"
            alt="trica"
            style={{ width: '75px', height: 'auto' }}
          />
        </Topbar>
        <div className={classes.container}>
          <Grid container>
            <Grid xs={12} sm={4} className={classes.gridContainer}>
              <Typography className={classes.description}>Page not found...</Typography>
              <Typography className={classes.subDescription}>
                Sorry, the page you are looking for doesn&apos;t exist
              </Typography>
              <ActionButton
                onClick={() => {
                  navigate('/');
                }}
              >
                BACK TO DASHBOARD
              </ActionButton>
            </Grid>
          </Grid>
        </div>
      </div> */}
    </div>
  );
};
export default PageNotFound;
