import { Box, Stack } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { DropdownButton } from '../../../ui-library/components/buttons/dropdown-button';
import { ActionOptionsList } from '../constants/IndividualDealConstant';
import PublishedDeal from './PublishedDeal';
import DraftDeal from './DraftDeal';

const IndividualDeal = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: id,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: id,
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  return (
    <Stack sx={{ overflowX: 'hidden' }} gap={2}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <DropdownButton
            options={ActionOptionsList}
            onOptionClick={(value: string) => {
              alert(value);
            }}
            buttonText="Actions"
          />
        </Box>
      </Stack>
      <DraftDeal />
      <PublishedDeal id={id} />
    </Stack>
  );
};

export default IndividualDeal;
