import React, { FC } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { useStyles } from './styles';

const InviteBackerModal: FC<any> = ({ openModal, handleClose }) => {
  const classes = useStyles();

  return (
    <CommonModal
      submitText="continue"
      isOpen={openModal}
      handleClose={handleClose}
      title="Invite Backer to Syndicate"
      hideButtons
    >
      <div style={{ width: '500px', overflow: 'auto' }}></div>
    </CommonModal>
  );
};

export default InviteBackerModal;
