import { getColor } from '../../colors';

export const checkBox = {
  color: getColor('primary', 500),
  '&.Mui-checked': {
    color: getColor('primary', 700)
  },
  '&.Mui-disabled': {
    color: '#BDBDBD',
    pointerEvents: 'auto',
    '&:hover': { backgroundColor: 'transparent' },
    cursor: 'not-allowed'
  }
};

export const checkBoxLabel = {
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    fontWeight: '400',
    color: getColor('neutral', 900)
  }
};

export const dropDownMenu = {
  borderRadius: '8px',
  marginTop: '16px',
  boxShadow:
    'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  '& .MuiList-root': {
    paddingTop: '0',
    paddingBottom: '0'
  }
};

export const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};
export const paperPropsRight = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};
