import React, { ReactNode, CSSProperties } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Divider, Box, Typography, Grid } from '@mui/material';
import { getColor } from '../../colors';

const useStyles = makeStyles(() =>
  createStyles({
    headerRoot: {
      display: 'flex',
      alignItems: 'center',
      height: '44px',
      backgroundColor: '#EAE9F1'
    },
    header: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center'
    },
    divider: {
      backgroundColor: getColor('primary', 300),
      margin: '0 8px 0 12px !important',
      height: '30px !important'
    },
    emailText: {
      wordBreak: 'break-all',
      lineHeight: 'normal',
      minWidth: 'auto',
      paddingRight: 1
    }
  })
);

type headerDetails = {
  /**
  * HeaderTitle  as a String.
  * e.g. 
        "Name",
  */
  headerTitle: string;

  /**
  * HeaderData  as a String.
  * e.g. 
        "Schedule 1",
  */
  headerData: string;
  /**
  * HeaderType to handle different style as a String.
  * e.g. 
        "email",
  */
  type?: string;
};

type styles = {
  /**
   * custom styles for headerTitle
   */
  headerTitle?: CSSProperties;
  /**
   * custom styles for headerData
   */
  headerData?: CSSProperties;
  /**
   * custom styles for root element
   */
  root?: CSSProperties;
  /**
   * custom styles for divider element
   */
  divider?: CSSProperties;
};

export type HeaderDetailProps = {
  /**
  * HeaderDetails as an array of objects.
  * e.g.
    [
      { headerTitle: 'Name', headerData: 'Schedule 1' },
      { headerTitle: 'Name', headerData: 'Schedule 2' }
    ]
  */
  headerDetails: headerDetails[];

  /**
   * Make your Own style.
   * e.g. 
          "style={{ headerTitle: {fontSize: '10px'}, headerData: {fontSize: '10px'}, root: {height: '40px'}, divider: {backgroundColor: '#EAE9F1'}}",
   */
  style?: styles;
};

export function HeaderDetail(props: HeaderDetailProps) {
  const { headerDetails, style = { headerTitle: {}, headerData: {}, root: {}, divider: {} } } = props;
  const classes = useStyles();
  return (
    <Box className={classes.headerRoot} sx={{ ...style.root }}>
      {headerDetails.map((data, index) => (
        <>
          {index !== 0 ? (
            <Divider orientation="vertical" classes={{ root: classes.divider }} sx={{ ...style.divider }} />
          ) : null}
          <Typography
            variant="h4"
            sx={{
              color: getColor('neutral', 900),
              fontFamily: 'Work Sans',
              lineHeight: '30px',
              fontSize: '16px',
              fontWeight: 500,
              paddingX: '5px',
              minWidth: 'fit-content',
              ...style.headerTitle
            }}
          >
            {data.headerTitle} :
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: getColor('neutral', 900),
              fontFamily: 'Work Sans',
              lineHeight: 'normal',
              fontSize: '14px',
              fontWeight: 700,
              fontStyle: 'normal',
              minWidth: 'fit-content',
              ...style.headerData
            }}
            className={data.type == 'email' ? classes.emailText : ''}
          >
            {data.headerData}
          </Typography>
        </>
      ))}
    </Box>
  );
}
