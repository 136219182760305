import React, { useState } from 'react';
import { Grid, Box, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';
import { getColor } from '../../colors';
import { LVThemeProvider } from '../theme/lvtheme';

const useStyles = makeStyles({
  cardContainer: {
    padding: '12px',
    alignContent: 'flex-start'
  },
  title: {
    height: '21px'
  },
  tooltip: {
    backgroundColor: `${getColor('shades', 0)} !important`,
    color: `${getColor('shades', 100)} !important`,
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.4) !important',
    padding: '16px !important',
    right: '8px !important'
  },
  tooltipArrow: {
    '&:before': { backgroundColor: `${getColor('shades', 0)} !important` },
    fontSize: '0.9rem !important',
    left: '4px !important'
  },
  tooltipImage: {
    display: 'inline',
    paddingLeft: '8px',
    verticalAlign: 'text-bottom'
  },
  arrowIcon: {
    width: '24px',
    height: 'auto',
    cursor: 'pointer',
    marginLeft: 8
  }
});

export type OptionWithEmployeesStatsticCardProps = {
  /**
   * statistic card title eg. 'options granted'
   */
  title: string;
  /**
   * statistic value eg. '34'
   */
  value: string | number;
  /**
   * tooltip text i.e. 'total opions granted to employees'
   */
  helpText?: string;
  /**
   * height of the stastic card. example : 94px
   */
  height?: string;
  /**
   * width of the stastic card. example : 222px
   */
  width?: string;
  /**
   * Padding of the stastic card value. example : 8px
   */
  valuePaddingTop?: string;
  /**
   * function for click on icon
   */
  onClick?: Function;
};

export function OptionWithEmployeesStatsticCard({
  title,
  value,
  helpText,
  height,
  width,
  valuePaddingTop,
  onClick
}: OptionWithEmployeesStatsticCardProps) {
  const styles = useStyles();
  const [isHovering, setHoverState] = useState<boolean>(false);

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <LVThemeProvider>
      <Box
        sx={{
          border: '1px solid #ECECEC',
          borderRadius: '10px',
          textAlign: 'left',
          overflow: 'hidden',
          minWidth: '200px'
        }}
      >
        <Grid
          item
          xs={12}
          container
          height={height ?? '5.8rem'}
          width={width ?? '13.7rem'}
          className={styles.cardContainer}
          spacing={0}
          style={{ background: 'white', width: '100%', height: '100%' }}
        >
          <Grid container item xs={12}>
            <Grid item xs={10}>
              <Typography
                variant="heading_05_medium"
                textTransform="uppercase"
                color={getColor('border', 300)}
                lineHeight="21px"
                sx={{
                  width: '100%',
                  fontWeight: 500,
                  letterSpacing: '0.5px',
                  paddingBottom: '8px'
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {helpText && (
                <Tooltip
                  title={helpText}
                  placement="top-start"
                  arrow
                  classes={{ arrow: styles.tooltipArrow, tooltip: styles.tooltip }}
                  sx={{ position: 'relative', width: '10%' }}
                >
                  <img
                    data-testid="info-tooltip"
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/InfoBlueIcon.svg"
                    alt="info"
                    className={styles.tooltipImage}
                  />
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            height={'32px'}
            style={{ paddingTop: `${valuePaddingTop ? valuePaddingTop : '4px'}` }}
            alignItems="center"
          >
            <Typography variant="heading_04_bold" color={getColor('primary', 700)}>
              {value}
            </Typography>
            {onClick && (
              <div
                className={styles.arrowIcon}
                onMouseEnter={() => {
                  setHoverState(true);
                }}
                onMouseLeave={() => {
                  setHoverState(false);
                }}
                onClick={handleClick}
              >
                <img
                  src={
                    isHovering
                      ? 'https://dsjvxb1plg419.cloudfront.net/v2.0/Simple-Dark-Right-Arrow.svg'
                      : 'https://dsjvxb1plg419.cloudfront.net/v2.0/Vector-2.svg'
                  }
                  alt="arrow-icon"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    </LVThemeProvider>
  );
}
