import { makeStyles } from '@mui/styles';
import { getColor } from '../../colors';

export const useStyles = makeStyles({
  minus: {
    margin: '0px 5px',
    display: 'flex',
    alignItems: 'center'
  }
});

export const radioButtonLabel = {
  color: getColor('neutral', 900),
  fontFamily: 'Work Sans',
  marginRight: '30px',
  marginBottom: '20px',
  '& .MuiRadio-root': {
    padding: '0px 8px 0px 11px'
  },
  '& .MuiTypography-root': {
    fontSize: '14px'
  }
};

export const menuStyle = {
  '& .MuiPaper-root': {
    width: '305px',
    height: '339px',
    borderRadius: '8px',
    '& .MuiList-root': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  }
};

export const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};
export const paperPropsRight = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    maxHeight: 'calc(100% - 32px) !important',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};
